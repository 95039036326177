/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveSignatureDataDto } from '../models/ActiveSignatureDataDto';
import type { AddInvestmentsDto } from '../models/AddInvestmentsDto';
import type { DiSignDTO } from '../models/DiSignDTO';
import type { ExistingInvestmentsDto } from '../models/ExistingInvestmentsDto';
import type { FinancialCombinedDataDto } from '../models/FinancialCombinedDataDto';
import type { FinancialComprehensiveDTO } from '../models/FinancialComprehensiveDTO';
import type { FinancialObjectiveDto } from '../models/FinancialObjectiveDto';
import type { FinancialSimplifiedDTO } from '../models/FinancialSimplifiedDTO';
import type { FinCombinedAssetLiabilityDto } from '../models/FinCombinedAssetLiabilityDto';
import type { KycFormMetaRequestDTO } from '../models/KycFormMetaRequestDTO';
import type { Object } from '../models/Object';
import type { RemoveSignatureDataDto } from '../models/RemoveSignatureDataDto';
import type { StampDTO } from '../models/StampDTO';
import type { UndoSignatureDataDto } from '../models/UndoSignatureDataDto';
import { request as __request } from '@openapi/request';

export class KycFormService {

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async getKycForm(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycForm/meta/${kycFormOid}`,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async postKycFormList(
        kycFormOid: string,
        requestBody: Object,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycForm/meta/${kycFormOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async addKyc(
        requestBody: Object,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycForm/add`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async addEfnaKyc(
        requestBody: Object,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/kycForm/add`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async postKycFormListV3(
        kycFormOid: string,
        requestBody: KycFormMetaRequestDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/kycForm/meta/${kycFormOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async cloneEfnaKyc(
        kycFormOid: string
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/clone/${kycFormOid}`
        });
        return result.body;
    }
    
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async getKycFormList(
        requestBody: Object,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycForm/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async deleteKycForm(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/v2/kycForm/${kycFormOid}`,
        });
        return result.body;
    }

    /**
     * @param postalCode
     * @param unitNo
     * @returns any
     * @throws ApiError
     */
    public static async getAddressByPostalCode(
        postalCode: string,
        unitNo: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycForm/residential/address`,
            query: {
                'postalCode': postalCode,
                'unitNo': unitNo,
            },
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateFinancialComprehensive(
        kycFormOid: string,
        requestBody: FinancialComprehensiveDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/kycForm/financialComprehensive/${kycFormOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async getFinancialData(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/financialCombinedData/${kycFormOid}`,
        });
        return result.body;
    }
    
        /**
     * @param kycFormOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
        public static async updateFinancialData(
            kycFormOid: string,
            requestBody: any,
        ): Promise<any> {
            const result = await __request({
                method: 'PUT',
                path: `/web/api/kycForm/financialCombinedData/${kycFormOid}`,
                body: requestBody,
            });
            return result.body;
        }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async getFinancialComprehensive(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/financialComprehensive/${kycFormOid}`,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async update(
        kycFormOid: string,
        requestBody: FinancialSimplifiedDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/kycForm/financialSimplified/${kycFormOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async getFinancialSimplified(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/financialSimplified/${kycFormOid}`,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateFinancialObjective(
        kycFormOid: string,
        requestBody: FinancialObjectiveDto,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/kycForm/financialObjective/${kycFormOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async getFinancialObjective(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/financialObjective/${kycFormOid}`,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async getFinancialComprehensiveSummary(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/financialComprehensiveSummary/${kycFormOid}`,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateFinancialAssetLiability(
        kycFormOid: string,
        requestBody: FinCombinedAssetLiabilityDto,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/kycForm/finAssetLiability/${kycFormOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async getFinancialAssetLiability(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/finAssetLiability/${kycFormOid}`,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updatefinancialCombinedData(
        kycFormOid: string,
        requestBody: FinancialCombinedDataDto,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/kycForm/financialCombinedData/${kycFormOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async getFinancialCombinedData(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/financialCombinedData/${kycFormOid}`,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateExistingInvestment(
        kycFormOid: string,
        requestBody: ExistingInvestmentsDto,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/kycForm/existingInvestments/${kycFormOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async getExistingInvestment(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/existingInvestments/${kycFormOid}`,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param clientRef
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async addInvestments(
        kycFormOid: string,
        clientRef: string,
        requestBody: AddInvestmentsDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycForm/investments/${kycFormOid}/${clientRef}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param clientRef
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateInvestments(
        kycFormOid: string,
        clientRef: string,
        requestBody: AddInvestmentsDto,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/kycForm/investments/${kycFormOid}/${clientRef}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param clientRef
     * @returns any
     * @throws ApiError
     */
    public static async getInvestments(
        kycFormOid: string,
        clientRef: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/investments/${kycFormOid}/${clientRef}`,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param clientRef
     * @returns any
     * @throws ApiError
     */
    public static async deleteAllInvestments(
        kycFormOid: string,
        clientRef: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/kycForm/investments/${kycFormOid}/${clientRef}`,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param clientRef
     * @param investmentId
     * @returns any
     * @throws ApiError
     */
    public static async deleteInvestments(
        kycFormOid: string,
        clientRef: string,
        investmentId: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/kycForm/investments/${kycFormOid}/${clientRef}/${investmentId}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async saveKycFormSignatures(
        requestBody: ActiveSignatureDataDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycForm/saveSignature`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @param formType
     * @param ecddFormType
     * @param ecddFormId
     * @returns any
     * @throws ApiError
     */
    public static async submitKycFormSignatures(
        oid: string,
        formType: string,
        ecddFormType?: string,
        ecddFormId?: string,
        formId?: string
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycForm/submitSignatures`,
            query: {
                '_oid': oid,
                'formType': formType,
                'ecddFormType': ecddFormType,
                'ecddFormId': ecddFormId,
                'formId': formId,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async stampedPdf(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycForm/stamped`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async stampedEcddSignatureV2(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycForm/stampedSignature/${kycFormOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async stampedPepSignature(
        requestBody: StampDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycForm/stamped/pepSignature`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async reSignature(
        requestBody: UndoSignatureDataDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycForm/reSignature`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param update
     * @returns any
     * @throws ApiError
     */
    public static async finalSubmission(
        kycFormOid: string,
        update: boolean,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycForm/finalSubmission/${kycFormOid}`,
            query: {
                'update': update,
            },
        });
        return result.body;
    }

    /**
     * @param esuppOid
     * @returns any
     * @throws ApiError
     */
    public static async getESupplimentarySignatureData(
        esuppOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/getESupplimentarySignatureData/${esuppOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async undoSignature(
        requestBody: RemoveSignatureDataDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycForm/undoSignature`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async validateProduct(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/validateProduct/${kycFormOid}`,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async fileContent(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/fileContent`,
            query: {
                'oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async disignToken(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/disignToken`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async saveDiSignResponse(
        requestBody: DiSignDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycForm/saveDiSignResponse`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid formType
     * @returns any
     * @throws ApiError
     */
    public static async getDiSignResponse(
        oid: string,
        formType: string
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/getDiSignResponse`,
            query: {
                'oid': oid,
                "formType": formType,
            },
        });
        return result.body;
    }

    /**
     * Check if given client is fellow adviser or not
     * @param idDocType
     * @param idNumber
     * @returns any
     * @throws ApiError
     */
    public static async checkFellowAdviser(
        idDocType: string,
        idNumber: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycForm/check-fellow-adviser`,
            query: {
                'idDocType': idDocType,
                'idNumber': idNumber,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async addDisignLogs(
        requestBody: any,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycForm/addDisignLogs`,
            body: requestBody,
        });
        return result.body;
    }

}