import { Base } from '../Base';
import { Default } from '@master/annotation/Default';
import { MaxChar } from '@master/annotation/MaxChar'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'
// import { ECDDCustomerInfoOptions } from '@master/config/ECDDCustomerInfoOptions';
// import { ECDDCustomerInfoOptions } from '@master/config/ECDDCustomerInfoOptions';
  
export class ClientModel  {
  id?:any
  @Default('individual')
  ecddtype = 'individual';

  @Default([])
  custInfoOptions: any[] = [];
  @Default([])
  complainceOpts: any[] = [];
  @Default([])
  purchaseOptions: any[] = [];
  @Default([])
  wealthOptions: any[] = [];
  @Default([])
  sourceOptions: any[] = [];
  @Default([])
  tpsourceOptions: any[] = [];
  tpothersSourceTextbox3 = ''
  @Default(null)
  othersTextbox: any = '';

  @Default(null)
  name: string = '';
  identityType: any  = '';
  dob: any  = '';
  gender: any  = '';
  @MaxChar(InputMaxChar.SMALL)
  contactEmail = ''
  @MaxChar(InputMaxChar.XSMALL)
  contactMobile = ''

  contactMobileCountryCode = ''
  tpdob: any  = '';
  tpgender: any  = '';
  citizenshipSpecified: any  = '';
  @Default(null)
  nric: any  = '';
  @Default(null)
  postalCode: any  = '';
  @Default(null)
  blockNumber: any  = '';
  @Default(null)
  streetName: any  = '';
  @Default(null)
  unitType: any = '';
  unitNumber: any  = '';
  @Default(null)
  buildingName: any  = '';
  tppostalCode: any  = '';
  @Default(null)
  tpblockNumber: any  = '';
  @Default(null)
  tpstreetName: any  = '';
  @Default(null)
  tpunitType: any = '';
  tpunitNumber: any  = '';
  @Default(null)
  tpbuildingName: any  = '';
  @Default(null)
  ecddcountry: any  = '';
  @Default(null)
  isLandedProperty: any  = '';
  @Default(null)
  relationShip: any  = '';
  @Default(null)
  pepFulName: any  = '';
  @Default(null)
  pepPosition: any  = '';
  pepOrganization: any  = '';
  pepRelationship: any  = '';
  panelshow: any = false;
  isThridPartyDetailsAvailable = false;

  @Default('')
  tpName: string = '';
  @Default('')
  tpNationality: string = '';
  @Default('')
  tpCountryOfResidence: string = '';
  @Default('')
  tpRelationWithClient: string = '';
  @Default('')
  tpIdentityNumber: string = '';
  @Default('')
  tpnric: string = '';
  @Default('')
  OthersPayersWealthSourceRemark: string = '';
  @MaxChar(InputMaxChar.SMALL)
  tpContactEmail = ''
  @MaxChar(InputMaxChar.XSMALL)
  tpContactMobile = ''

  tpContactMobileCountryCode = ''
  
  netAnnualIncome:number = null;
  annualSurplus:number = null;
  othersDescription:any = '';
  othersAmount:any = null;
  totalCash:number = null;
  totalAssets:number = null;
  totalInvestAssets:number = null;
  totalPersonalAssets:number = null;
  othersAssets:any = '';
  othersAssetsAmount:any = null;
  shortTermLiabilities:number = null;
  longTermLiabilities:number = null;
  othersAssetsLiabilities:any = null;
  othersAmountLiabilities:any = null;
  beneficialName:any = '';
  totalLiabilities = null;

  @Default(null)
  otherTextarea: string = '';
  @Default(null)
  othersWealthTextbox3: string = '';
  @Default(null)
  othersSourceTextbox3: string = '';
  @Default(null)
  othersTextbox3: string = '';
  @Default(null)
  othersTextbox23: string = '';
  @Default(false)
  included?:boolean = false;
  error = false
  signature:any = ''
  signatureDate:any = ''
  signature1:any = ''
  signatureDate1:any = ''
  signature2:any = ''
  signatureDate2:any = ''
  signature3:any = ''
  signatureDate3:any = ''
  isVisited = false

  /**
   * key to store the preview status of the PDF
   */
  isPreviewed: boolean = false
  signing_status?: 'draft' | 'pending' | 'completed' = 'draft'
}


export class ECDDFormModel extends Base {
  cOne: ClientModel;
  cTwo: ClientModel;
  beneficialOwner: ClientModel;
  thirdParty: ClientModel;
  additionalForm: ClientModel;
  listEcdd: ClientModel[]; // Array for additional clients

  constructor(params?: Partial<ECDDFormModel>) {
    super();
    this.cOne = params?.cOne || new ClientModel(); // Default to new ClientModel if undefined
    this.cTwo = params?.cTwo || new ClientModel();
    this.beneficialOwner = params?.beneficialOwner || new ClientModel();
    this.thirdParty = params?.thirdParty || new ClientModel();
    this.additionalForm = params?.additionalForm || new ClientModel();
    this.listEcdd = params?.listEcdd || []; // Default to an empty array
  }
}


