import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "logo-wrapper" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { id: "nav-list" }
const _hoisted_5 = ["id", "onClick", "onMouseover", "title"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 0,
  class: "side-logo-wrapper"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  class: "profile",
  ref: "popup"
}
const _hoisted_15 = ["src"]
const _hoisted_16 = ["href", "onClick"]
const _hoisted_17 = { class: "menu-wrapper" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "spacing" }
const _hoisted_20 = { slot: "body" }
const _hoisted_21 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_down = _resolveComponent("arrow-down")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AppDialog = _resolveComponent("AppDialog")!
  const _component_AlertPopup = _resolveComponent("AlertPopup")!
  const _directive_svg_inline = _resolveDirective("svg-inline")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass([{ mini: _ctx.mini }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.imageType === 'svg')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "logo1w",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args)))
          }, [
            _createElementVNode("div", { innerHTML: _ctx.logo }, null, 8, _hoisted_2)
          ]))
        : (_ctx.imageType === 'others')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "logo1",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args)))
            }, [
              _createElementVNode("img", {
                src: _ctx.logo,
                alt: " image",
                class: "logo-type"
              }, null, 8, _hoisted_3)
            ]))
          : _createCommentVNode("", true)
    ]),
    _createElementVNode("nav", null, [
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, ({ disable, path, icon, label, dropdown }, index) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: index,
            to: path,
            class: "link",
            active: _ctx.isActive,
            href: _ctx.href,
            onClick: _ctx.navigate
          }, {
            default: _withCtx(({ href, navigate, isActive }) => [
              (!disable)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 0,
                    id: `nav-item-${index}`,
                    onClick: ($event: any) => (_ctx.setActive(index)),
                    onMouseout: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hovering=-1)),
                    onMouseover: ($event: any) => (_ctx.hovering=index),
                    class: _normalizeClass([{ 'has-drop': dropdown && dropdown.length > 0, 'dd-flip': _ctx.isOutOfView(`nav-item-${index}`), 'active-li': _ctx.activeIndex === index}, label]),
                    style: _normalizeStyle(_ctx.customStyle),
                    title: label
                  }, [
                    (label === 'Manage')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _withDirectives(_createElementVNode("img", {
                            src: icon,
                            alt: "svg image"
                          }, null, 8, _hoisted_6), [
                            [_directive_svg_inline]
                          ]),
                          _createElementVNode("span", null, [
                            _createTextVNode(_toDisplayString(label) + " ", 1),
                            _createVNode(_component_el_icon, { class: "el-icon--right" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_arrow_down)
                              ]),
                              _: 1
                            })
                          ])
                        ], 64))
                      : (label === 'Clients')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _withDirectives(_createElementVNode("img", {
                              src: icon,
                              alt: "svg image"
                            }, null, 8, _hoisted_7), [
                              [_directive_svg_inline]
                            ]),
                            _createElementVNode("span", null, _toDisplayString(label === 'Clients' && _ctx.currentUser.systemRole === 'supervisor' || _ctx.currentUser.systemRole === 'director' ? 'Clients/Advisers' : label), 1)
                          ], 64))
                        : (label === 'eFNA')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              ([0, 1].includes(_ctx.userAccessViews.length))
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "efna-menu-item",
                                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onEfnaClick && _ctx.onEfnaClick(...args)))
                                  }, [
                                    _withDirectives(_createElementVNode("img", {
                                      src: icon,
                                      alt: "svg image"
                                    }, null, 8, _hoisted_8), [
                                      [_directive_svg_inline]
                                    ]),
                                    _createElementVNode("span", null, _toDisplayString(label), 1)
                                  ]))
                                : (_openBlock(), _createBlock(_component_el_dropdown, {
                                    key: 1,
                                    trigger: "click"
                                  }, {
                                    dropdown: _withCtx(() => [
                                      _createVNode(_component_el_dropdown_menu, null, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userAccessViews, (item, i) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                              key: i,
                                              class: _normalizeClass({'hide': item.shouldHide}),
                                              onClick: ($event: any) => (_ctx.handleEfnaRoute(item.href))
                                            }, [
                                              _createVNode(_component_el_dropdown_item, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(item.label), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ], 10, _hoisted_10))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    default: _withCtx(() => [
                                      _createElementVNode("span", {
                                        class: "el-dropdown-link",
                                        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onEfnaClick && _ctx.onEfnaClick(...args)))
                                      }, [
                                        _withDirectives(_createElementVNode("img", {
                                          src: icon,
                                          alt: "svg image"
                                        }, null, 8, _hoisted_9), [
                                          [_directive_svg_inline]
                                        ]),
                                        _createElementVNode("span", null, _toDisplayString(label), 1)
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024))
                            ], 64))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                              _withDirectives(_createElementVNode("img", {
                                src: icon,
                                alt: "svg image"
                              }, null, 8, _hoisted_11), [
                                [_directive_svg_inline]
                              ]),
                              _createElementVNode("span", null, _toDisplayString(label), 1)
                            ], 64))
                  ], 46, _hoisted_5))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["to", "active", "href", "onClick"]))
        }), 128))
      ]),
      (_ctx.showSideLogo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _withDirectives(_createElementVNode("img", {
              id: "mochaLogo",
              class: "mocha-logo side-logo",
              src: _ctx.mochaIcon,
              alt: "svg image",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args)))
            }, null, 8, _hoisted_13), [
              [_directive_svg_inline]
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", {
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showDropdown = true)),
        class: "dropdown-pro"
      }, [
        _withDirectives(_createElementVNode("img", {
          id: "profile",
          class: "profile-logo side-logo",
          src: _ctx.profileIcon,
          alt: "svg image"
        }, null, 8, _hoisted_15), [
          [_directive_svg_inline]
        ]),
        _createVNode(_component_el_icon, { class: "el-icon--right" }, {
          default: _withCtx(() => [
            _createVNode(_component_arrow_down)
          ]),
          _: 1
        })
      ]),
      _withDirectives(_createElementVNode("div", {
        id: "myDropdown",
        class: "dropdown-content",
        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handlePopupClick && _ctx.handlePopupClick(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profileOptions, (item, i) => {
          return (_openBlock(), _createElementBlock("a", {
            key: i,
            class: _normalizeClass(item.label),
            href: item.href ? item.href : undefined,
            onClick: ($event: any) => (_ctx.handleEvent(item.event))
          }, [
            _createElementVNode("div", _hoisted_17, [
              (item.icon)
                ? _withDirectives((_openBlock(), _createElementBlock("img", {
                    key: 0,
                    id: "profile",
                    class: "profile-logo side-logo",
                    src: item.icon,
                    alt: "svg image"
                  }, null, 8, _hoisted_18)), [
                    [_directive_svg_inline]
                  ])
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_19, _toDisplayString(item.label), 1)
            ])
          ], 10, _hoisted_16))
        }), 128))
      ], 512), [
        [_vShow, _ctx.showDropdown]
      ])
    ], 512),
    (_ctx.enableVirtualMeeting)
      ? (_openBlock(), _createBlock(_component_AppDialog, {
          key: 0,
          class: "virtual-meeting-url",
          onClosed: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isCopied = false)),
          hideFooter: true
        }, {
          header: _withCtx(() => _cache[11] || (_cache[11] = [
            _createTextVNode("Virtual Meeting URL")
          ])),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("p", null, [
                _cache[12] || (_cache[12] = _createTextVNode("Click on the URL to copy: ")),
                _createElementVNode("strong", null, _toDisplayString(_ctx.isCopied ? 'Copied!' : ''), 1)
              ]),
              _createElementVNode("div", {
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.copyUrl && _ctx.copyUrl(...args))),
                class: "jitsi-url"
              }, [
                _createElementVNode("input", {
                  id: "url",
                  value: _ctx.jitsiUrl,
                  readonly: ""
                }, null, 8, _hoisted_21)
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isClosePopupVisible)
      ? (_openBlock(), _createBlock(_component_AlertPopup, {
          key: 1,
          showModal: _ctx.isClosePopupVisible,
          title: _ctx.noAccessDashboardAccessContent.title,
          message: _ctx.noAccessDashboardAccessContent.message,
          btnText: _ctx.noAccessDashboardAccessContent.btnText,
          type: _ctx.noAccessDashboardAccessContent.type,
          onClose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.isClosePopupVisible = false))
        }, null, 8, ["showModal", "title", "message", "btnText", "type"]))
      : _createCommentVNode("", true)
  ], 2))
}