import { options } from './Options'

export const IdentityTypeOptions: options[] = [
  { label: '', value: '' },
  { label: 'NRIC', value: 'nric' },
  { label: 'FIN', value: 'fin' },
  { label: 'Passport', value: 'passport' },
  { label: 'UEN', value: 'uen' },
  { label: 'Not available', value: 'not-available' }
]
