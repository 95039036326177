/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { IUploadDiSignPDF } from '@core/services/disign-client-side/ClientSideDiSignService';
import type { ActiveSignatureDataDto } from '../models/ActiveSignatureDataDto';
import type { addAdditionalCommentsDto } from '../models/addAdditionalCommentsDto';
import type { CallbackFormDto } from '../models/CallbackFormDto';
import type { CreateKycPdfDto } from '../models/CreateKycPdfDto';
import type { ESupplimentaryFormDto } from '../models/ESupplimentaryFormDto';
import type { ExportReportDto } from '../models/ExportReportDto';
import type { FileListDto } from '../models/FileListDto';
import type { KycPdfListRequestDto } from '../models/KycPdfListRequestDto';
import type { KycPdfSubmissionDto } from '../models/KycPdfSubmissionDto';
import type { SendUpdateDto } from '../models/SendUpdateDto';
import type { UpdateKycPdfDto } from '../models/UpdateKycPdfDto';
import type { UserDocumentsViewsDto } from '../models/UserDocumentsViewsDto';
import type { UserRemarksDto } from '../models/UserRemarksDto';
import { request as __request } from '@openapi/request';

export class KycPdfV2Service {

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async getKycPdf(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf/new`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async listKycPdf(
        requestBody: KycPdfListRequestDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async listKycPdfV3(
        requestBody: KycPdfListRequestDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/kycPdf/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async listKycBusiness(
        requestBody: KycPdfListRequestDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/kycPdf/dashboard/business`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async createKycPdf(
        requestBody: CreateKycPdfDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateKycPdf(
        requestBody: UpdateKycPdfDto,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/v2/kycPdf`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async saveKycPdfSignatures(
        requestBody: ActiveSignatureDataDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/saveSignature`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async submitKycPdfSignatures(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/submitSignatures`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async addUserRemarks(
        requestBody: UserRemarksDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/addUserRemarks`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async adviserCancel(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/adviserCancel`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async finaliseKycPdfSubmission(
        requestBody: KycPdfSubmissionDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/finalise`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateCallbackForm(
        requestBody: CallbackFormDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/callbackForm`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycPdfOid
     * @returns any
     * @throws ApiError
     */
    public static async deleteKycPdf(
        kycPdfOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/kycPdf/${kycPdfOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async createESupplimentaryForm(
        requestBody: ESupplimentaryFormDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/eSupplimentaryForm`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param eSupOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateESupplimentaryForm(
        requestBody: ESupplimentaryFormDto,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/kycPdf/eSupplimentaryForm`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param eSupOid
     * @returns any
     * @throws ApiError
     */
    public static async deleteESupplimentaryForm(
        eSupOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/kycPdf/eSupplimentaryForm/${eSupOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async uploadFile(
        requestBody: any,
        onProgress?: (percentage:number) => void
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/upload-file`,
            formData: requestBody,
            onProgress
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async reuploadFile(
        requestBody: any,
        onProgress?:(count:number) => void 
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/reupload-file`,
            formData: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async listFile(
        requestBody: FileListDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/list-file`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async deleteKycPdfFile(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/kycPdf/file/${oid}`,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async downloadKycPdfFile(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycPdf/file/${oid}`,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async downloadKycPdfFileV2(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf/file/${oid}`,
        });
        return result.body;
    }

    /**
     * @param kycPdfOid
     * @returns any
     * @throws ApiError
     */
    public static async getESupplimentaryForm(
        kycPdfOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycPdf/eSupplimentaryForm/${kycPdfOid}`,
        });
        return result.body;
    }

    /**
     * Get list of cases for EFNA Manager dashboard
     * @param searchField
     * @param searchFieldValue
     * @param oid
     * @param client1Name
     * @param client1Nric
     * @param dateOfSubmission
     * @param timeOfSubmission
     * @param pdfStatus
     * @param esubStatus
     * @param orderBy
     * @param wildcardMode
     * @param page
     * @param limit
     * @param retrieveAll
     * @param status
     * @param isProxyManagerView
     * @returns any
     * @throws ApiError
     */
    public static async viewManagerDashboard(
        searchField?: string,
        searchFieldValue?: string,
        oid?: any[],
        client1Name?: string,
        client1Nric?: string,
        dateOfSubmission?: string,
        timeOfSubmission?: string,
        pdfStatus?: string,
        esubStatus?: string,
        orderBy?: string,
        wildcardMode?: string,
        page?: number,
        limit?: number,
        retrieveAll?: boolean,
        status?: string,
        isProxyManagerView?: boolean,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycPdf/dashboard/manager`,
            query: {
                'searchField': searchField,
                'searchFieldValue': searchFieldValue,
                'oid': oid,
                'client1Name': client1Name,
                'client1Nric': client1Nric,
                'dateOfSubmission': dateOfSubmission,
                'timeOfSubmission': timeOfSubmission,
                'pdfStatus': pdfStatus,
                'esubStatus': esubStatus,
                'orderBy': orderBy,
                'wildcardMode': wildcardMode,
                'page': page,
                'limit': limit,
                'retrieveAll': retrieveAll,
                'status': status,
                'isProxyManagerView': isProxyManagerView,
            },
        });
        return result.body;
    }

    /**
     * Get list of cases for EFNA Adviser dashboard
     * @param searchField
     * @param searchFieldValue
     * @param oid
     * @param client1Name
     * @param client1Nric
     * @param dateOfSubmission
     * @param timeOfSubmission
     * @param pdfStatus
     * @param esubStatus
     * @param orderBy
     * @param wildcardMode
     * @param page
     * @param limit
     * @param retrieveAll
     * @param status
     * @param isProxyManagerView
     * @returns any
     * @throws ApiError
     */
    public static async viewAdviserDashboard(
        searchField?: string,
        searchFieldValue?: string,
        oid?: any[],
        client1Name?: string,
        client1Nric?: string,
        dateOfSubmission?: string,
        timeOfSubmission?: string,
        pdfStatus?: string,
        esubStatus?: string,
        orderBy?: string,
        wildcardMode?: string,
        page?: number,
        limit?: number,
        retrieveAll?: boolean,
        status?: string,
        isProxyManagerView?: boolean,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycPdf/dashboard/adviser`,
            query: {
                'searchField': searchField,
                'searchFieldValue': searchFieldValue,
                'oid': oid,
                'client1Name': client1Name,
                'client1Nric': client1Nric,
                'dateOfSubmission': dateOfSubmission,
                'timeOfSubmission': timeOfSubmission,
                'pdfStatus': pdfStatus,
                'esubStatus': esubStatus,
                'orderBy': orderBy,
                'wildcardMode': wildcardMode,
                'page': page,
                'limit': limit,
                'retrieveAll': retrieveAll,
                'status': status,
                'isProxyManagerView': isProxyManagerView,
            },
        });
        return result.body;
    }

    /**
     * Get case details for EFNA Manager dashboard
     * @param kycPdfOid
     * @returns any
     * @throws ApiError
     */
    public static async getCaseDetails(
        kycPdfOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycPdf/caseDetails/${kycPdfOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async viewbusinessAssuranceDashboard(
        requestBody: KycPdfListRequestDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/dashboard/businessAssurance`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get list of cases for EFNA Business Assurance dashboard
     * @param kycPdfOid
     * @returns any
     * @throws ApiError
     */
    public static async markAsAdhoc(
        kycPdfOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/dashboard/businessSupport/markAsAdhoc/${kycPdfOid}`,
        });
        return result.body;
    }

    /**
     * Sends case for update with comments
     * @param kycPdfOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async sendUpdateCaseDetails(
        kycPdfOid: string,
        requestBody: SendUpdateDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/caseDetails/sendUpdate/${kycPdfOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get list of cases for EFNA Compliance dashboard based on the status
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async complianceDashboard(
        requestBody: KycPdfListRequestDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/dashboard/compliance`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycPdfOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async addComments(
        kycPdfOid: string,
        requestBody: addAdditionalCommentsDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/dashboard/addComments/${kycPdfOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async viewproxyDashboard(
        requestBody: KycPdfListRequestDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/dashboard/proxyDashboard`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycPdfOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async userDocumentViews(
        kycPdfOid: string,
        requestBody: UserDocumentsViewsDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/user-document-views/${kycPdfOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get list of Failed NBR Lodgement from EFNA
     * @param searchField
     * @param searchFieldValue
     * @param oid
     * @param client1Name
     * @param client1Nric
     * @param dateOfSubmission
     * @param timeOfSubmission
     * @param pdfStatus
     * @param esubStatus
     * @param orderBy
     * @param wildcardMode
     * @param page
     * @param limit
     * @param retrieveAll
     * @param status
     * @param isProxyManagerView
     * @returns any
     * @throws ApiError
     */
    public static async nbrLodgementFailedData(
        searchField?: string,
        searchFieldValue?: string,
        oid?: any[],
        client1Name?: string,
        client1Nric?: string,
        dateOfSubmission?: string,
        timeOfSubmission?: string,
        pdfStatus?: string,
        esubStatus?: string,
        orderBy?: string,
        wildcardMode?: string,
        page?: number,
        limit?: number,
        retrieveAll?: boolean,
        status?: string,
        isProxyManagerView?: boolean,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycPdf/dashboard/nbrLodgementFailedData`,
            query: {
                'searchField': searchField,
                'searchFieldValue': searchFieldValue,
                'oid': oid,
                'client1Name': client1Name,
                'client1Nric': client1Nric,
                'dateOfSubmission': dateOfSubmission,
                'timeOfSubmission': timeOfSubmission,
                'pdfStatus': pdfStatus,
                'esubStatus': esubStatus,
                'orderBy': orderBy,
                'wildcardMode': wildcardMode,
                'page': page,
                'limit': limit,
                'retrieveAll': retrieveAll,
                'status': status,
                'isProxyManagerView': isProxyManagerView,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async exportReportData(
        requestBody: ExportReportDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/export/report`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async cloneCases(
        kycFormOid: string,
        requestBody: ExportReportDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/clone/${kycFormOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any
     * @throws ApiError
     */
    public static async storeDiSignPDF(requestBody: IUploadDiSignPDF): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/storeDisignPdf`,
            formData: requestBody,
        });
        return result.body;
    }
}