<template>
  <header :class="[{ mini: mini }]">
    <div class="logo-wrapper">
      <div class="logo1w" v-if="imageType === 'svg'" @click="goToHome">
        <div v-html="logo"> </div>
      </div>
      <div class="logo1" v-else-if="imageType === 'others'" @click="goToHome">
        <img :src="logo" alt=" image" class="logo-type" />
      </div>
    </div>
    <nav>
      <ul id="nav-list">
        <router-link
          v-for="({ disable, path, icon, label, dropdown }, index) in header"
          :key="index"
          :to="path"
          v-slot="{ href, navigate, isActive }"
          class="link"
          :active="isActive"
          :href="href"
          @click="navigate"
        >
          <li v-if="!disable" :id="`nav-item-${index}`" @click="setActive(index)" @mouseout="hovering=-1" @mouseover="hovering=index" :class="[{ 'has-drop': dropdown && dropdown.length > 0, 'dd-flip': isOutOfView(`nav-item-${index}`), 'active-li': activeIndex === index}, label]" :style="customStyle" :title="label">
            <template v-if="label === 'Manage'">
              <img v-svg-inline :src="icon" alt="svg image" />
              <span>{{ label }} <el-icon class="el-icon--right"><arrow-down /></el-icon>
              </span>
            </template>

            <!-- <div id="myDropdown" class="dropdown-content-efna" v-show="showefnaDropdown">
                  <a v-for="(item, i) in efnaOptions" :key="i" :class="item.label" :href="item.href">
                    <p class="spacing">{{ item.label }}</p>
                  </a>
               </div> -->
            <template v-else-if="label === 'Clients'">
              <img v-svg-inline :src="icon" alt="svg image" />
                <span>{{ label === 'Clients' && currentUser.systemRole === 'supervisor' || currentUser.systemRole === 'director' ? 'Clients/Advisers' : label }} </span>
            </template>
            <template v-else-if="label === 'eFNA'">
              <span
                v-if="[0, 1].includes(userAccessViews.length)"
                class="efna-menu-item"
                @click="onEfnaClick"
              >
                <img v-svg-inline :src="icon" alt="svg image" />
                <span>{{ label }} </span>
              </span>
              <el-dropdown v-else trigger="click">
                <span class="el-dropdown-link" @click="onEfnaClick">
                  <img v-svg-inline :src="icon" alt="svg image" />
                  <span>{{ label }} </span>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <div v-for="(item, i) of userAccessViews" :key="i" :class="{'hide': item.shouldHide}" @click="handleEfnaRoute(item.href)" >
                      <el-dropdown-item>
                        {{ item.label }}
                      </el-dropdown-item>
                    </div>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
            <template v-else>
              <img v-svg-inline :src="icon" alt="svg image" />
              <span>{{ label }}</span>
            </template>
          </li>
        </router-link>
      </ul>
      <div v-if="showSideLogo" class="side-logo-wrapper">
        <img id="mochaLogo" v-svg-inline class="mocha-logo side-logo" :src="mochaIcon" alt="svg image" @click="goToHome"/>
      </div>
    </nav>
    <div class="profile" ref="popup">
      <div @click="showDropdown = true" class="dropdown-pro">
        <img id="profile" v-svg-inline class="profile-logo side-logo" :src="profileIcon" alt="svg image" />
        <el-icon class="el-icon--right"><arrow-down /></el-icon>
      </div>
      <div id="myDropdown" class="dropdown-content" v-show="showDropdown" @click="handlePopupClick">
        <a v-for="(item, i) of profileOptions" :key="i" :class="item.label" :href="item.href ? item.href : undefined"
          @click="handleEvent(item.event)">
          <div class="menu-wrapper">
            <img id="profile" v-if="item.icon" v-svg-inline class="profile-logo side-logo" :src="item.icon"
              alt="svg image" />
            <div class="spacing">{{ item.label }}</div>
          </div>
        </a>
      </div>

    </div>
    <AppDialog v-if="enableVirtualMeeting" class="virtual-meeting-url" @closed="isCopied = false" :hideFooter="true">
      <template #header>Virtual Meeting URL</template>
      <div slot="body">
        <p>Click on the URL to copy: <strong>{{ isCopied ? 'Copied!' : '' }}</strong></p>
        <div @click="copyUrl" class="jitsi-url">
          <input id="url" :value="jitsiUrl" readonly>
        </div>
      </div>
    </AppDialog>
    <AlertPopup
      v-if="isClosePopupVisible"
      :showModal="isClosePopupVisible"
      :title="noAccessDashboardAccessContent.title"
      :message="noAccessDashboardAccessContent.message"
      :btnText="noAccessDashboardAccessContent.btnText"
      :type="noAccessDashboardAccessContent.type"
      @close="isClosePopupVisible = false"
    />
  </header>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { HeaderItem } from '@core/model/structure/HeaderStructure'
import { cid, container } from 'inversify-props'
import { IProjectSettings } from '@master/config/IProjectSettings'
import VideocamIcon from '@core/components/header/assets/videocam.svg'
import hamburgerIcon from '@core/assets/svg/hamburger.svg'
import MochaLogo from '@core/assets/mocha-logo.svg'
import AppDialog from '@core/components/AppDialog.vue'
import profileIcon from '@core/assets/svg/profile.svg'
import settingsIcon from '@core/assets/svg/Settings-home.svg'
import LogoutIcon from '@core/assets/svg/signout.svg'
import FAQIcon from '@core/assets/svg/faq.svg'
import AlertPopup from '@/views/kyc-form/component/AlertPopup.vue'
import { EfnaConfigurationService } from '@core/openapi/services/efna-configuration/api/services/EfnaConfigurationService'

interface Dictionary<T> {
  [index: string]: T;
}
@Component({
  components: {
    AppDialog,
    AlertPopup
  },
})
export default class AppDynamicHeader extends Vue {

  @Prop({ required: true }) private readonly header!: HeaderItem[]

  @Prop()
  private mini
  profileIcon = profileIcon
  showDropdown = false

  private scrollPosition = null

  private hamburgerIcon = hamburgerIcon
  private isMenuOpen = false

  private jitsiUrl = ''
  private isJitsiUrlShow = false
  private isCopied = false
  private hovering = -1
  private dropDownWidth = 200
  private activeIndex = -1
  showefnaDropdown = false
  manageCollapse = false
  customStyle
  href
  navigate
  isActive

  async mounted() {
    await this.efnaOptions();

    window.addEventListener('click', this.handleOutsideClick); // Detects clicks on the window
  }

  beforeUnmount() {
    window.removeEventListener('click', this.handleOutsideClick); // Clean up the listener
  }

  // Handles clicks inside the popup
  handlePopupClick(event) {
    event.stopPropagation(); // Prevents the event from bubbling up
  }

  // Detects clicks outside the popup
  handleOutsideClick(event) {
    if (this.showDropdown && !this.$refs.popup?.contains(event.target)) {
      this.showDropdown = false;
    }
  }

  startVirtualMeeting () {
    this.jitsiUrl = 'https://meet.jit.si/' + this.randomStr(32)
    this.isJitsiUrlShow = true
  }

  randomStr (len: number) {
    const arr = '1234567890abcdefghijklmnopqrstuvwxyz'
    let ans = ''
    for (let i = len; i > 0; i--) {
      ans += arr[Math.floor(Math.random() * arr.length)]
    }
    return ans
  }

  setActive (index) {
    this.activeIndex = index
  }

  get currentUser () {
    return this.$store.getters.currentUser
  }

  get isCurrentAdminUser () {
    return this.$store.getters.isCurrentAdminUser
  }

  get logo () {
    return localStorage.getItem('logo') || this.$store.getters.logoData
  }

  get imageType () {
    return localStorage.getItem('imageType') || this.$store.getters.imageType
  }

  getHref (routeName: string, params: Dictionary<string>, legacyUrl: string) {

    const { href } = this.$router.resolve({
      name: routeName,
      params
    })

    return this.$router.currentRoute.value.matched.length > 0 ? href : legacyUrl
  }

  userAccessViews = [];
  async efnaOptions() {
    const response = await EfnaConfigurationService.getUserAccessViews(this.currentUser?.oid);
    const views = [];

    response.filter((item) => {
      if (item.name === 'Adviser') {
        views.push({
          href: 'home-adviser',
          label: 'Adviser',
          disabled: false,
        });
      } else if (item.name === 'Supervisor' || item.name === 'Manager') { // TODO have to remove Supervisor once BE change is done
        views.push({
          // ref: SSP-4846
          // shouldHide: this.currentUser.systemRole === 'adviser',
          href: 'home-manager',
          label: 'Manager',
        });
      } else if (item.name === 'Business Support') {
        views.push({
          // based on the story SSP-4552
          // shouldHide: !this.isCurrentAdminUser,
          href: 'home-business-support',
          label: 'Business Support',
        });
      } else if (item.name === 'Business Assurance') {
        views.push({
          href: 'home-business-assurance',
          label: 'Business Assurance',
        });
      } else if (item.name === 'Compliance') {
        views.push({
          href: 'home-compliance',
          label: 'Compliance',
        });
      }
    });

    this.userAccessViews = views;
  }

  isClosePopupVisible = false;
  noAccessDashboardAccessContent = {
    title: `No dashboard access to eFNA`,
    message: `You have not been assigned any view access to the eFNA dashboard. Please contact your system administrator for more details.`,
    btnText: 'Close',
    type: 'warning',
  };
  onEfnaClick() {
    if (this.userAccessViews.length === 0) {
      this.isClosePopupVisible = true;
      return;
    }

    if (this.userAccessViews.length === 1) {
      this.$router.push({ name: this.userAccessViews[0].href });
    }

    return;
  }

  /**
   * 
   * @param href 
   * eFNA dropdown menu link/route(href) handler
   */
  handleEfnaRoute(href:string) {
    if (href) {
      this.$router.push({ name: href })
    }
  }

  get profileOptions () {
    return [
      {
        label: this.currentUser.userPersonalInfo?.fullname
      },
      {
        href: this.getHref('User Profile', { userOid: this.currentUser.oid }, `/user/${this.currentUser.oid}/profile/summary/`),
        icon: profileIcon,
        label: 'Profile'
      },
      {
        href: '/web/api/pdfView/FAQ.pdf',
        icon: FAQIcon,
        target: '_blank',
        label: 'FAQ'
      },
      {
        event: 'logout',
        icon: LogoutIcon,
        label: 'Sign out'
      }
      // {
      //   icon: homeIcon,
      //   href: '/landing-home',
      //   label: 'Home'
      // },
      // {
      //   icon: homeIcon,
      //   href: '/landing-home',
      //   label: 'Home'
      // },
    ]
  }

  handleEvent (event) {
    if (event) {
      this.$emit(event)
    }
  }

  copyUrl () {
    const url: HTMLInputElement = document.getElementById('url') as HTMLInputElement
    url.select()
    url.setSelectionRange(0, 99999)
    document.execCommand('copy')
    this.isCopied = true
  }

  toggleMenu () {
    this.isMenuOpen = !this.isMenuOpen

    // if (this.isMenuOpen) {
    //   console.log(document.getElementById('app'))
    //   document.getElementById('app').style.filter = 'blur(8px)'
    // }
  }

  // for flipping the dropdown list so that it stays within viewport
  isOutOfView (eleId) {
    const ele = document.getElementById(eleId)
    if (!ele) return false
    const pose = ele.getBoundingClientRect()
    if (document.documentElement.clientWidth - this.dropDownWidth < pose.left) {
      return true
    } else return false
  }

  get showSideLogo () {
    const { useSideLogo } = container.get<IProjectSettings>(cid.IProjectSettings)
    return useSideLogo
  }

  get enableVirtualMeeting () {
    const { virtualMeeting } = container.get<IProjectSettings>(cid.IProjectSettings)
    return virtualMeeting ? virtualMeeting.enable : false
  }

  get videocamIcon () {
    return VideocamIcon
  }

  get mochaIcon () {
    return MochaLogo
  }

  // Methods
  goToHome () {
    this.$router.push('/landing-home')
  }
}
</script>

<style lang="scss" scoped>
@import '@/design';
$font-size-base: 16px;
$dark-grey: #191919;
$menu-button-bar-height: 4px;
$menu-button-bar-width: $menu-button-bar-height * 8;
$menu-overlay-width: calc(30% - 4rem);
$menu-overlay-height: calc(30% - 4rem);

$transition-04s: all 0.4s ease;

$layer1: 1;
$layer2: 22;
$layer3: 33;
$toggleSize: 40px;
$nav-icon-width: 4.4rem;
// $nav-icon-height: 3rem;
$nav-dropdown-width: 200px;
$toggleMargin: 10px;
$toggleLine: 4px;
$toggleColor: red;

.blur-class {
  filter: blur(8px)
}

:deep(.dd-class) .el-dropdown + .el-dropdown {
  margin-left: 15px;
}

:deep(.dd-class) .el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.efna-menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 3px;

  span {
    padding-top: 6px;
  }
}

ul.el-dropdown-menu {
  margin: 0;
}

:deep(.el-dropdown-menu__item) {
  padding: 7px 16px !important;
}

.el-dropdown-menu {
  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    background-color: unset !important;
    color: unset !important;
    text-decoration: none;
    cursor:pointer;
  }
}

.menu-overlay {
  background-color: white;
  height: 100vh;
  // width: 0;
  // right: 0;
  position: fixed;
  padding: 8rem 2rem;
  visibility: hidden;
  opacity: 0;
  // transition: $transition-04s;
  transform: translate(10%, 40%);
  // transform: translateY(40%);
  z-index: $layer2;

  &.open {
    opacity: 1;
    visibility: visible;
    // height: $menu-overlay-width;
    transform: translate(9%, 41%);
  }
}

.background-overlay {
  background-color: rgba($color: $dark-grey, $alpha: 0.5);
  // width: 100%;
  // height: 100vh;
  z-index: $layer1;
  opacity: 0;
  // transition: $transition-04s;
  position: fixed;

  &.bg-overlay {
    opacity: 1;
  }
}

.change_color {
  background: white;
  position: fixed;
}

.nav {
  display: flex;
  flex-direction: row;

  &__item {
    text-decoration: none;
    color: $dark-grey;
    font-size: 2rem;
    // transition: $transition-04s;
    position: relative;
    align-self: flex-start;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &::after {
      content: "";
      background-color: $dark-grey;
      height: 2px;
      width: 0;
      position: absolute;
      bottom: -0.5rem;
      left: 0;
      // transition: $transition-04s;
    }

    &:hover::after {
      width: 100%;
    }
  }
}

.logo {

}

.button {
  text-decoration: none;
  font-size: 2rem;
  padding: 1rem 2rem;
  color: white;
  background-color: $dark-grey;
  align-self: flex-start;
  // transition: $transition-04s;

  &:hover {
    background-color: rgba($color: $dark-grey, $alpha: 0.75);
  }
}



.content {
  max-width: 1440px;
  padding: 0 2rem;
}

// .Profile {
//   position: relative;
//   padding-left: 40px;
//   &::before{
//     content: "";
//     position: absolute;
//     border-left: 1px solid #D8D8D8;
//     height: 28px;
//     top: 25%;
//     left: 20px;
//   }
// }

header {
  display: flex;

  &.mini {
    .logo-wrapper {
      // height: 3rem;
      padding: 0 0.25rem;
    }
    nav {
      display: flex;
      // align-items: center;
      justify-content: space-evenly;
      width: 75%;
      // flex: 1;
      ul#nav-list a {
        & > li.active-li {
          border-bottom: 4px solid $color-primary;
          color: $color-primary;
        }
        & > li {
          margin-left: 30px;
          margin-right: 10px;
          height: 100%;
          padding: 15px 6px;
          a:not(.dropdown-item) {
            border-radius: 5px;
            // width: $nav-icon-width;
            padding: 0;
            // border: 1px solid $color-other-form;
            svg {
              margin: auto;
              outline: none;
            }
            &:active {
              transform: scale(0.8);
              opacity: 0.5;
            }
          }
          span {
            vertical-align: super;
            // line-height: 1.5;
            font-size: 12px;
            margin-left: 6px;
          }
          &.has-drop:hover {
            // margin-left: 6px;
            cursor:pointer;
            .el-dropdown-menu {
              // margin-top: 30rem;
            }
          }
        }
      }
    }
  }

  .logo-wrapper {
    height: $header-height;
    padding: 0.25rem;
    width: 170px;
    height: 64px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo1 .logo-type {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      ;

      &:hover {
        cursor: pointer;
      }
    }
  }

  nav {
    width: 79%;

    ul#nav-list {
      list-style-type: none;
      display: flex;
      // flex-wrap: wrap;
      // justify-content: flex-end;
      align-items: center;
      margin-right: 10px;
      height: 100%;

      & > li.active-li {
        border-bottom: 4px solid red;
      }

      & > li {
        position: relative;
        margin: 0;
        // padding: 0;
        height: 100%;
        // transition: all 0.3s ease;

        &:is(.focus, :hover) a:not(.dropdown-item) {
          text-decoration: none;
        }

        a {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          color: #434343;
          text-align: center;
          font-weight: 600;
          padding: 5px 10px;
          text-decoration: none;
          cursor: pointer;
          height: 100%;
          // transition: all 0.5s ease;
          p {
            margin: 0;
            text-align: center;
            line-height: 1;
            font-size: 0.8rem;
            word-wrap: break-word;
            // transition: all 0.5s ease;
          }
          &:hover {
            // color: $color-other-form;
            text-decoration: none;
          }
        }

        .dropdown {
          position: absolute;
          left: 0.5rem;
          top: 3.4rem;
          background-color: #fff;
          width: 200px;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          border: 1px solid $color-other-form;
          border-radius: 0 1rem 1rem 1rem;
          z-index: 100;
          transform: scale(0);
          opacity: 0;
          transform-origin: top left;
          // transition: all 0.3s ease;
          .dropdown-item {
            padding: 1rem 1.5rem;
            border-bottom: 1px inset $color-other-form;

            &:hover {
              background-color: $color-other-form;
              color: #fff;
            }
          }
          .dropdown-item:first-child {
            border-top-right-radius: 1rem;
          }
          .dropdown-item:last-child {
            border-bottom: none;
            border-bottom-right-radius: 1rem;
            border-bottom-left-radius: 1rem;
          }
        }
        &.dd-flip .dropdown {
          // left: calc( -#{$nav-dropdown-width} + 0.5rem + #{$nav-icon-width});
          // border-radius: 1rem 0 1rem 1rem;
          // transform-origin: right top;
        }
      }

      li:hover {
        // .dropdown {
        //   transform: scale(1);
        //   opacity: 1;
        // }
      }
    }
  }
}

.dropdown-content,
.dropdown-content-efna {
  position: absolute;
  background-color: #FFFFFF;
  width: 282px;
  overflow: auto;
  // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 64px;
  right: 10%;
  border-radius: 16px;

  & .menu-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 56px;
    padding: 16px 24px;
    cursor: pointer !important;
    width: 100%;
    pointer-events: all;
    z-index: 100;

    &:hover {
      background: #F5F5F5;
    }
  }
}

.spacing {
  font-family: Sharp Sans Display No1;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  padding-top: 2px;
}

.dropdown-content a {
  color: #434343;
  text-decoration: none;
  display: flex;

  &:first-child {
    background: #434343;
    color: white;

    & .menu-wrapper {
      pointer-events: none;
    }
  }
}

.dropdown-content-efna a {
  color: #707070;
  padding:12px 16px;
  text-decoration: none;
  display: flex;

  &:hover {
    color: white;
    background: $color-primary;
  }

  .spacing {
    &:hover {
      color: white;
    }
  }
}

.profile {
  margin-top: 14px;

  &>.dropdown-content>a:last-child {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 50px;
      height: 1px;
      background-color: #EAEAEA;
      transform: translateX(-50%);
      width: 234px;
    }

    & .spacing {
      padding-top: 0px;
    }
  }

  &>.dropdown-content>a:first-child {    
    & .spacing {
      font-size: 16px;
    }
  }
}

.hide {
  display: none;
}

.dropdown-pro {
  position: relative;
  display: inline-block;
}
</style>
