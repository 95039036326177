import { RouteRecordRaw } from 'vue-router'
export const efnaConfigRouter = ((): RouteRecordRaw[] => {
    return [
      {
        path: '/efna-configuration/',
        component: () => import('@core/pages/tenant/ConfigContainer.vue'),
        meta: {
            // subject: ['commissionRecord', 'commissionReportRecord', 'incomeDeductible']
        },
        redirect: '/efna-configuration/dashboard-configuration',
        children: [
            {
                path: 'dashboard-configuration',
                name: 'dashboard-configuration',
                component: () => import('@core/pages/tenant/Dashboard.vue'),
                meta: {
                    subject: ['efnaGroup', 'efnaBackendChecker']
                }
            },
            {
                path: 'bor',
                name: 'bor',
                component: () => import('@core/pages/tenant/Bor.vue')
            },
            // {
            //     path: 'transaction-records',
            //     name: 'Transaction',
            //     component: () => import('@core/pages/commission/components/Transactions.vue')
            // },
            // {
            //     path: 'commission-records',
            //     name: 'Commissions',
            //     component: () => import('@core/pages/commission/components/Commissions.vue')
            // },
            // {
            //     path: 'payment-voucher',
            //     name: 'Payment Voucher',
            //     component: () => import('@core/pages/commission/components/PaymentVoucher.vue')
            // },
            // {
            //     path: 'intermediaries',
            //     name: 'Intermediaries',
            //     component: () => import('@core/pages/commission/components/Intermediaries.vue')
            // },
            // {
            //     path: 'reports-statements',
            //     name: 'Reports and Statements',
            //     component: () => import('@core/pages/commission/components/ReportsStatements.vue')
            // },
        ]
      },
      {
        path: '/commission-configuration',
        component: () => import('@core/pages/tenant/CommissionConfig.vue'),
      },
      {
        path: '/filenet-configuration',
        component: () => import('@core/pages/tenant/FileNetConfig.vue'),
      }
    ]
})()
