/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TenantConfigurationRequestDTO } from '../models/TenantConfigurationRequestDTO';
import type { TenantConfigurationResponseDTO } from '../models/TenantConfigurationResponseDTO';
import { request as __request } from '@openapi/request';

export class TenantConfigService {

    /**
     * @returns TenantConfigurationResponseDTO Retrieve the data of Tenant Configuration
     * @throws ApiError
     */
    public static async retrieveTenantConfigList(): Promise<TenantConfigurationResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/tenant/tenant-config/list`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns TenantConfigurationResponseDTO Update the data of Tenant Configuration
     * @throws ApiError
     */
    public static async updateTenantInformation(
        requestBody: TenantConfigurationRequestDTO,
    ): Promise<TenantConfigurationResponseDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/tenant/tenant-config/update-tenant-config`,
            body: requestBody,
        });
        return result.body;
    }

}