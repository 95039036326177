<template>
  <div v-if="isShowHeader" style="z-index: 999;" class="change_color">
    <AppDynamicHeader v-if="!isModernTheme" @logout="logout" :header="headerStructure" />
    <AppDynamicIconHeader v-else :mini="true" @logout="logout" :header="headerStructure" />
    <!-- <AppHamburgerHeader class ="viewPort-tab" :withLogo="true" @logout="logout" :header="HamburgerfullHeaderStructure()" /> -->
  </div>
  <AlertPopup
      v-if="logOutAlert"
      :showModal="logOutAlert"
      class="session-alert"
      :title="`Are you still there ?`"
      :message="`You’ve been idle and will be automatically logged out in ${formattedCountdownTime}`"
      btnText="Log out"
      actionBtnText="Yes, I’m here"
      type="warning"
      :showTwoButton="true"
      @close="handleSessionExpiration"
      @action="resetSessionTimer"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import AppDynamicHeader from '@core/components/header/AppDynamicHeader.vue'
import AppDynamicIconHeader from '@core/components/header/AppDynamicIconHeader.vue'
import AppDynamicChangeheader from '@core/components/header/AppDynamicChangeHeader.vue'
import AppHamburgerHeader from '@core/components/header/AppHamburgerHeader.vue'
import { HeaderItem } from '@core/model/structure/HeaderStructure'
import KYCViewIcon from '@core/assets/svg/kyc-no-fill.svg'
import ResourcesIcon from '@core/assets/svg/resources-no-fill.svg'
import ReportIcon from '@core/assets/svg/report-no-fill.svg'
import AdminIcon from '@core/assets/svg/admin-no-fill.svg'
import clientIcon from '@core/assets/svg/client-list.svg'
import nbrIcon from '@core/assets/svg/nbr-no-fill.svg'
import NoticeIcon from '@core/components/header/assets/notice.svg'
import homeIcon from '@core/assets/svg/home.svg'
import efnaIcon from '@core/assets/svg/efna.svg'
import profileIcon from '@core/assets/svg/profile.svg'
import manageIcon from '@core/assets/svg/manage.svg'
import reportsIcon from '@core/assets/svg/reports.svg'
import FAQIcon from '@core/assets/svg/faq-no-fill.svg'
import LogoutIcon from '@core/assets/svg/logout-no-fill.svg'
import closeIcon from '@core/assets/svg/cancel.svg'
import API from '@core/services/API'
import { inject } from 'inversify-props'
import { IProjectSettings } from '@master/config/IProjectSettings'
import dropdownIconUp from '@core/assets/svg/arrow-up.svg'
import dropdownIconDown from '@core/assets/svg/arrow-down.svg'
import { OktaService } from '@core/openapi/services/okta/api'
import { SharedVariable } from '@core/model/SharedVariable'
import { useRouter } from 'vue-router'
import AlertPopup from "@/views/kyc-form/component/AlertPopup.vue";

interface Dictionary<T> {
  [index: string]: T;
}

@Component({
  components: {
    AppDynamicHeader,
    AppDynamicIconHeader,
    AlertPopup,
    AppDynamicChangeheader,
    AppHamburgerHeader
  }
})
export default class TheHeader extends Vue {
  @inject()
  private $api: API

  @inject()
  private projectSettings: IProjectSettings

  @inject()
  private sharedVariable: SharedVariable

  @Prop({ default: () => [] }) private customHeaders: any
  @Prop({ default: 'KYC' }) private kycViewLabel: string

  private mini = true
  private scrollPosition = null
  private windowResize = null
  private isKYCHeader = false
  private isEFNAUrl = true
  private sessionTimeout= 60 * 60 * 1000 // 60 minutes in milliseconds
  private sessionTimer= null
  private warningTimer= null
  private countdown = 5 * 60 // 5 minutes in milliseconds
  private warningDialogTimer = null
  private logOutAlert = false

  get uiService () {
    return this.sharedVariable.uiService
  }

  get isModernTheme () {
    return this.projectSettings.theme === 'Modern'
  }

  get currentUser () {
    return this.$store.getters.currentUser
  }

  get isCurrentAdminUser () {
    return this.$store.getters.isCurrentAdminUser
  }

  get isBscReviewer () {
    return this.$store.getters.isBscReviewer
  }

  get isShowHeader () {
    return !this.isEFNAUrl
  }

  get formattedCountdownTime () {
    const minutes = Math.floor(this.countdown / 60);
    const seconds = this.countdown % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  @Watch('$route')
  routeUpdated (to) {
    const current_url = to?.path?.split?.("/")
    this.isEFNAUrl = current_url?.includes?.("kyc-form")
  }

  get isProductAdmin () {
    return this.$store.getters.isProductAdmin
  }

  get headerStructure (): HeaderItem[] {
    return this.currentUser.systemRole === 'bsc-reviewer' ? this.bscHeaderStructure() : this.fullHeaderStructure()
  }

  get currentAllPermission () {
    return this.$store.getters.currentAllPermission
  }

  isDisabled (subject: string, access: string) {
    return this.sharedVariable.isPermissionDisabled(subject, access, this.currentAllPermission)
  }

  bscHeaderStructure (): HeaderItem[] {
    return [
      {
        event: 'logout',
        icon: LogoutIcon,
        label: 'Logout'
      }
    ]
  }

  HamburgerfullHeaderStructure (): HeaderItem[] {
    return [
      {
        icon: closeIcon,
        label: 'cancel'
      },
      {
        icon: homeIcon,
        path: '/landing-home',
        label: 'Home'
      },
      {
        icon: efnaIcon,
        label: 'eFNA',
        disable: this.isBscReviewer || this.isProductAdmin,
        iconUp: dropdownIconUp,
        iconDown: dropdownIconDown,
        dropdown: [
          {
            path: '/web/home-adviser',
            label: 'Adviser View'
          },
          {
            disable: this.currentUser.systemRole === 'adviser',
            path: '/web/home-supervisor',
            label: 'Supervisor View'
          },
          {
            disable: !this.isCurrentAdminUser,
            path: '/web/home-submission',
            label: 'Admin View'
          }
        ]
      },
      {
        disable: this.isCurrentAdminUser,
        icon: clientIcon,
        path: '/client-management',
        label: 'Clients'
      },
      {
        disable: !['supervisor', 'director'].includes(this.currentUser.systemRole),
        icon: nbrIcon,
        path: '/rep-list',
        label: 'Advisers'
      },
      {
        disable: this.isDisabled('client', 'read'),
        icon: clientIcon,
        path: '/client-list',
        label: 'Clients'
      },
      // {
      //   icon: nbrIcon,
      //   path: '',
      //   label: 'NBR'
      // },
      // {
      //   icon: recruitIcon,
      //   path: '',
      //   label: 'Recruit'
      // },
      {
        disable: !this.isCurrentAdminUser,
        icon: manageIcon,
        path: '/admin',
        label: 'Manage',
        iconUp: dropdownIconUp,
        iconDown: dropdownIconDown,
        dropdown: this.currentUser.systemRole === 'adviser' ? [
          {
            path: '/adviser-sales-code-update',
            label: 'Agent Codes Mass Update'
          },
        ]:[
          {
            path: '/approvals',
            label: 'Approvals'
          },
          {
            path: '/commission',
            label: 'Commission'
          },
          {
            path: '/user-list',
            label: 'User Administration'
          },
          {
            path: '/group-list',
            label: 'Branch and Manager Administration'
          },
          {
            path: '/admin-assumptions',
            label: 'Assumptions List',
            disable: !this.projectSettings.useAssumptionsList
          },
          {
            path: '/admin-products',
            label: 'Product List'
          },
          {
            path: '/admin-pdfUpload',
            label: 'PDF Upload',
            disable: this.projectSettings.useResourcesModule
          },
          {
            path: '/payee-list',
            label: 'Intermediaries List'
          },
          {
            path: '/company-codes-list',
            label: 'Product Principal List'
          },
          {
            path: '/agent-codes-mass-update',
            label: 'Agent Codes Mass Update'
          },
          {
            path: '/client-management',
            label: 'CMS',
            disable: this.projectSettings.disableCMSModule
          },
          {
            path: '/cpd/summary',
            label: 'CPD'
          },
          // {
          //   path: '/commission-module',
          //   label: 'Commission Module'
          // },
          {
            path: '/bsc-home',
            label: 'BSC Module'
          },
          {
            path: '/disign-panel',
            label: 'Disign Panel'
          },
          {
            path: '/compensation-setup',
            label: 'Compensation Setup',
            disable: !this.projectSettings.useHierarchySetup
          },
          {
            path: '/admin-survey',
            label: 'Survey',
            disable: !this.projectSettings.useKycSurvey
          },
          {
            path: '/scheduler',
            label: 'Scheduler',
            disable: !this.projectSettings.useScheduler
          }
        ]
      },
      ...this.customHeaders,
      // {
      //   icon: ResourcesIcon,
      //   path: '/resources',
      //   label: 'Resources',
      //   disable: !this.projectSettings.useResourcesModule
      // },
      {
        path: '/pdfView/SFA_APL.pdf',
        icon: NoticeIcon,
        target: '_blank',
        label: 'Notices',
        disable: this.projectSettings.useResourcesModule
      },
      // {
      //   icon: ReportIcon,
      //   path: '/report-management',
      //   label: 'Report',
      //   disable: !this.projectSettings.useReportModule
      // },
      // {
      //   path: '/web/api/pdfView/FAQ.pdf',
      //   icon: FAQIcon,
      //   target: '_blank',
      //   label: 'FAQ'
      // },
      {
        path: this.getpath('User Profile', { userOid: this.currentUser.oid }, `/user/${this.currentUser.oid}/profile/summary/`),
        icon: profileIcon,
        label: 'Profile'
      },
      // {
      //   event: 'logout',
      //   icon: LogoutIcon,
      //   label: 'Logout'
      // }
    ]
  }

  fullHeaderStructure () {
    return [
      {
        icon: homeIcon,
        path: '/landing-home',
        label: 'Home'
      },
      {
        icon: efnaIcon,
        label: 'eFNA',
        disable: this.isBscReviewer || this.isProductAdmin,
        iconUp: dropdownIconUp,
        iconDown: dropdownIconDown,
        path: '#',
      },
      // {
      //   disable: this.currentUser.systemRole !== 'supervisor' && this.currentUser.systemRole !== 'director',
      //   icon: clientIcon,
      //   path: '/client-adviser',
      //   label: 'Clients/Advisers'
      // },
      {
        // disable: !this.currentUser.isSuperUser && this.currentUser.systemRole !== 'adviser',
        icon: clientIcon,
        path: '/client-list',
        label: 'Clients'
      },
      {
        icon: reportsIcon,
        path: '/report-management',
        label: 'Reports',
        disable: !this.projectSettings.useReportModule
      },
      // {
      //   icon: nbrIcon,
      //   path: '',
      //   label: 'NBR'
      // },
      // {
      //   icon: recruitIcon,
      //   path: '',
      //   label: 'Recruit'
      // },
      // [
      //     {
      //       path: '/agent-codes-mass-update',
      //       label: 'Agent Codes Mass Update'
      //     },
      //     {
      //       path: '',
      //       label: 'Audit Log'
      //     }]
      {
        // disable: !this.currentUser.isSuperUser,
        icon: manageIcon,
        path: '/manage',
        label: 'Manage',
        iconUp: dropdownIconUp,
        iconDown: dropdownIconDown,
        dropdown: [
          {
            href: '/approvals',
            label: 'Approvals'
          },
          {
            href: '/commission',
            label: 'Commission'
          },
          {
            href: '/user-list',
            label: 'User Administration'
          },
          {
            href: '/group-list',
            label: 'Branch and Manager Administration'
          },
          {
            href: '/admin-assumptions',
            label: 'Assumptions List',
            disable: !this.projectSettings.useAssumptionsList
          },
          {
            href: '/admin-products',
            label: 'Product List'
          },
          {
            href: '/admin-pdfUpload',
            label: 'PDF Upload',
            disable: this.projectSettings.useResourcesModule
          },
          {
            href: '/payee-list',
            label: 'Intermediaries List'
          },
          {
            href: '/company-codes-list',
            label: 'Product Principal List'
          },
          {
            href: '/adviser-sales-code-update',
            label: 'Agent Codes Mass Update'
          },
          {
            href: '/client-management',
            label: 'CMS',
            disable: this.projectSettings.disableCMSModule
          },
          {
            href: '/cpd/summary',
            label: 'CPD'
          },
          // {
          //   href: '/commission-module',
          //   label: 'Commission Module'
          // },
          {
            href: '/bsc-home',
            label: 'BSC Module'
          },
          {
            href: '/disign-panel',
            label: 'Disign Panel'
          },
          {
            href: '/compensation-setup',
            label: 'Compensation Setup',
            disable: !this.projectSettings.useHierarchySetup
          },
          {
            href: '/admin-survey',
            label: 'Survey',
            disable: !this.projectSettings.useKycSurvey
          },
          {
            href: '/scheduler',
            label: 'Scheduler',
            disable: !this.projectSettings.useScheduler
          },
          {
          href: '/country-master',
          label: 'Country Master',
          },
        ]
      },
      // ...this.customHeaders,
      // {
      //   icon: ResourcesIcon,
      //   path: '/resources',
      //   label: 'Resources',
      //   disable: !this.projectSettings.useResourcesModule
      // },
      // {
      //   path: '/pdfView/SFA_APL.pdf',
      //   icon: NoticeIcon,
      //   target: '_blank',
      //   label: 'Notices',
      //   disable: this.projectSettings.useResourcesModule
      // },

      // {
      //   path: '/web/api/pdfView/FAQ.pdf',
      //   icon: FAQIcon,
      //   target: '_blank',
      //   label: 'FAQ'
      // },
      // {
      //   path: this.getpath('User Profile', { userOid: this.currentUser.oid }, `/user/${this.currentUser.oid}/profile/summary/`),
      //   icon: profileIcon,
      //   label: 'Profile'
      // },
      // {
      //   event: 'logout',
      //   icon: LogoutIcon,
      //   label: 'Logout'
      // }
    ]
  }

  created () {
    window.addEventListener('scroll', this.handleScroll)
  }

  getpath (routeName: string, params: Dictionary<string>, legacyUrl: string) {
    // const router = useRouter()

    // const { href } = router.resolve({
    //   name: routeName,
    //   params
    // })

    // return router.currentRoute.value.matched.length > 0 ? href : legacyUrl

    const { href } = this.$router.resolve({
      name: routeName,
      params
    })

    return this.$router.currentRoute.value.matched.length > 0 ? href : legacyUrl
  }

  getCookie (cname) {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
  setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
    document.cookie = name + "=" + (value || "") + expires + "; path=/; SameSite=Strict; Secure";
    }
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    }
    getCookieExpiry(cookieName) {
      const cookies = document.cookie.split(';');
      for (let cookie of cookies) {
        const [name, value] = cookie.trim().split('=');
        if (name === cookieName) {
          const jwtPayload = this.parseJwt(value)
          const expiryTime = new Date(jwtPayload.exp * 1000);
          return expiryTime ? new Date(Number(expiryTime)): new Date(new Date().getTime() + (1 * 60 * 60 * 1000));;
        }
      }
      return null; // Return null if the cookie is not found or doesn't have an expiry time
    }
    clearCookies() {
      this.setCookie('idToken', '', -1);
      this.setCookie('refreshToken', '', -1);
    }

 async logout() {
  this.uiService.loading.addLoadingTask('logout');
  this.uiService.alert.show({
    type: 'warning',
    message: 'Logging out now...'
  });

  try {
    const idToken = this.getCookie('idToken');
    if (!idToken) {
      // If no idToken is present, redirect to OKTA Login URL
      window.location.href = __OKTA_LOGIN_URL__;
      return; // Exit early
    }

    const { logoutUrl } = await OktaService.logout({
      accessToken: idToken
    });

    if (logoutUrl) {
      window.location.href = `${logoutUrl}?id_token_hint=${idToken}&post_logout_redirect_uri=${__LOGOUT_REDIRECT_URL__}`;
    } else {
      console.error('Logout error1:');
      window.location.href = __OKTA_LOGIN_URL__;
      return;
    }
  } catch (error) {
    if (error.name === 'TypeError') {
      console.error('Logout error2:', error);
      window.location.href = __OKTA_LOGIN_URL__;
      return;
    } else {
      // Handle specific errors (e.g., network issues, OKTA service issues)
      this.uiService.alert.show({
        type: 'error',
        message: 'Unable to perform logout. Please try again.'
      });
      console.error('Logout error:', error); // Log the error for debugging
    }
  } finally {
    // Always remove the loading indicator, even if there was an error
    this.uiService.loading.removeLoadingTask('logout');
    localStorage.clear()
    sessionStorage.clear()
    this.clearCookies()
  }
  }

  handleScroll () {
    this.scrollPosition = window.scrollY
  }

  mounted () {
    this.checkSession();
  }
  checkSession() {
    let sessiontime = this.getCookieExpiry("idToken")
    const currentTime = new Date();
    if (sessiontime) {
      this.sessionTimeout = sessiontime.getTime() - currentTime.getTime();
      if ((this.countdown * 1000) < this.sessionTimeout) {
        this.startSessionTimer(this.sessionTimeout);
      } else {
        this.handleSessionExpiration();
      }
    } else {
      this.startSessionTimer(this.sessionTimeout);
    }
  }
  startSessionTimer(remainingTime) {
    // Set a timer to show a warning before session expires
    this.warningTimer = setTimeout(() => {
      if(!this.logOutAlert){
        this.showWarningDialog();
      } 
    }, remainingTime - (this.countdown * 1000));

    // Set a timer for session expiration
    this.sessionTimer = setTimeout(() => {
      this.handleSessionExpiration();
    }, remainingTime);
  }
  showWarningDialog() {
      // Show the warning dialog with two buttons
      this.logOutAlert = true
      // Set a timer to auto-logout if no button is clicked
      this.warningDialogTimer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.handleSessionExpiration();
        }
      }, 1000);
    }

    handleSessionExpiration() {
      this.logOutAlert = false
      clearInterval(this.warningDialogTimer);
      this.logout();
    }
    async resetSessionTimer() {
      // Clear existing timers
      this.uiService.loading.addLoadingTask('refreshToken');
      try {
        this.logOutAlert = false
        this.countdown = 300
        clearTimeout(this.warningTimer);
        clearTimeout(this.sessionTimer);
        clearInterval(this.warningDialogTimer);
        const oldRefreshToken = this.getCookie('refreshToken');
        const tokenResponse = await OktaService.refreshToken(oldRefreshToken);
        const { idToken, idtokenExpiry, refreshToken, refreshTokenExpiry } = tokenResponse;
        const itExpiryDate = new Date(Number(idtokenExpiry));
        const atExpiryDate = new Date(Number(refreshTokenExpiry));
        if (refreshToken) {
          document.cookie = 'refreshToken=' + refreshToken + ';expires=' + atExpiryDate.toUTCString() + ';path=/; SameSite=Strict; Secure'
        }
        if (idToken) {
          document.cookie = 'idToken=' + idToken + ';expires=' + itExpiryDate.toUTCString() + ';path=/; SameSite=Strict; Secure'
        }
       
        this.checkSession();
      } catch (error) {
        this.handleSessionExpiration()
      } finally{
        this.uiService.loading.removeLoadingTask('refreshToken');
      }
    }
  beforeDestroy() {
    // Clear timers when the component is destroyed
    clearTimeout(this.warningTimer);
    clearTimeout(this.sessionTimer);
    clearInterval(this.warningDialogTimer);
  }
}
</script>

<style lang="scss" scoped>
@import '@/design';

@media (min-width: 486px) and (max-width: 992px) {
  .viewPort-main {
    visibility: hidden;
    max-height: 0;
  }
  .viewPort-tab {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .viewPort-main {
    visibility: visible;
  }
  .viewPort-tab {
    visibility: hidden;
    max-height: 0;
  }
}

.change_color {
  background: white;
  width: 100%;
  height: 72px;
  padding: 9px 2px 9px 2px;
}

.session-alert .alert-popup-wrapper__close {
    gap: 24px !important;
}

.virtual-meeting-url {
  :deep(.modal-body) {
    p {
      margin-bottom: 5px;
    }

    div.jitsi-url {
      input {
        width: 100%;
        color: inherit;
        border: 1px solid #BABABA;
        border-radius: 5px;
        padding: 5px;
        text-align: center;
        outline: none;

        &:hover {
          cursor: pointer;
          background: #BABABA;
          color: white;
        }
      }
    }
  }
}
</style>
