<template>
  <el-dialog v-model="showPopup" center modal-class="popup-overlay" class="popup">
    <div class="alert-popup-wrapper">
      <div class="alert-popup-wrapper__body">
        <img class="success-error-icon" src="@/assets/success.gif" v-if="type === 'success'" />
        <img class="warning-icon" src="@/assets/warning.gif" v-if="type === 'warning'" />
        <img class="warning-icon" src="@/assets/info.gif" v-if="type === 'alert'" />
        <div class="alert-popup-wrapper__body__text-box">
          <span class="alert-popup-wrapper__body__text-box__title" v-if="title"> {{ title }} </span>
          <slot name="msg">
            <span class="alert-popup-wrapper__body__text-box__msg"> {{ message }} </span>
            
          </slot>
          <span class="alert-popup-wrapper__body__text-box__msg"> {{ secondmessage }} </span>
        </div>
      </div>

      <div class="alert-popup-wrapper__actions">
        <button :class="[showTwoButton ===undefined || !showTwoButton ? 'btn-default' : 'btn-bordered']" @click="$emit('close')">
          {{ btnText }}
        </button>
        <button class="btn-default" v-if="showTwoButton" @click="$emit('action')">
          {{ actionBtnText }}
        </button>
      </div>

      <div class="alert-popup-wrapper__close" @click="$emit('close')">
        <img src="@/assets/cross_line.svg" />
      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator'

@Component({
  components: {
  }
})
export default class AlertPopup extends Vue {
  @Prop() readonly type!: 'success' | 'error' | 'warning' | 'alert';
  @Prop() readonly title?: string;
  @Prop() readonly message?: string;
  @Prop() readonly secondmessage?: string;
  @Prop({ default: 'Cancel' }) btnText?: string;
  @Prop({ default: 'Confirm' }) actionBtnText?: string;
  @Prop() showModal!: boolean;
  @Prop() showTwoButton?: boolean;
  public showPopup: boolean = false;

  mounted() {
    this.showPopup = this.showModal
  }
}
</script>

<style lang="scss">
.popup {
  padding: 24px;
  display: flex;
  width: 486px !important;
  /* padding: var(--small-24-px-40-px-sm-24, 24px); */
  flex-direction: column;
  align-items: center;
  /* gap: var(--small-24-px-40-px-sm-24, 24px); */
  border-radius: var(--extra-small-4-px-20-px-xs-16, 16px);
  background: var(--System-Colours-system-whiteBackground, #FFF);
  /* Align center */
  margin: 0;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }
}

.popup-overlay {
  background-color: rgba($color: #000000, $alpha: 0.7);
}

.alert-popup-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    img {
      flex-shrink: 0;
      width: 50px;
      height: 50px;
    }

    &__text-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      &__title {
        font-family: 'Sharp Sans Display No1';
        font-size: 18px;
        font-weight: 600;
        line-height: 25.2px;
        letter-spacing: 0.015em;
        text-align: center;
        color: #434343;
      }

      &__msg {
        font-family: 'Sharp Sans Display No1';
        font-size: 14px;
        font-weight: 500;
        line-height: 22.4px;
        text-align: center;
        color: #434343;
      }
    }
  }

  &__actions {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    button {
      min-width: 144px;
      height: 44px;
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }
  }
}
.btn-default,.btn-bordered{
  font-size:14px !important
}
</style>