import { TypeBase } from '../TypeBase'

export default class RequestSupervisorCallbackStatus extends TypeBase {
  status: string = ''
  triggerStatus: string = ''  // New key

  constructor(type: 'client_0' | 'client_1' = 'client_0', triggerStatus: string = '') {
    super(`${type}_callback`)
    this.triggerStatus = triggerStatus  // Initialize triggerStatus
  }

  extractData(rawData: any) {
    super.extractData(rawData[this.type]) // Extract base data
    // Extract the triggerStatus from the rawData if it exists
    if (rawData[this.type] && rawData[this.type].triggerStatus) {
      this.triggerStatus = rawData[this.type].triggerStatus
    }
    if (rawData[this.type] && rawData[this.type].status) {
      this.status = rawData[this.type].status
    }
  }

  convertToRawData() {
    const results = super.convertToRawData()
    return {
      [this.type]: {
        status: this.status, // Include the status without the prefix
        triggerStatus: this.triggerStatus // Include the triggerStatus
      }
    }
  }
}
