/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FullMochaUserInfoExtraAttributes } from '../models/FullMochaUserInfoExtraAttributes';
import type { UserMemberListRequestDTO } from '../models/UserMemberListRequestDTO';
import type { UserMemberListResponseDTO } from '../models/UserMemberListResponseDTO';
import { request as __request } from '@openapi/request';

export class UserMemberService {

    /**
     * @param requestBody 
     * @returns UserMemberListResponseDTO 
     * @throws ApiError
     */
    public static async listAllUserAccountBasedOnHierarchy(
requestBody: UserMemberListRequestDTO,
): Promise<UserMemberListResponseDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/user-member/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param accountOid 
     * @returns string 
     * @throws ApiError
     */
    public static async findMemberInStructure(
accountOid: string,
): Promise<Array<string>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/user-member/find/${accountOid}`,
        });
        return result.body;
    }

    /**
     * @returns FullMochaUserInfoExtraAttributes 
     * @throws ApiError
     */
    public static async listOfMemberInStructure(): Promise<Array<FullMochaUserInfoExtraAttributes>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/user-member/list`,
        });
        return result.body;
    }

    /**
     * @param accountOid 
     * @returns UserMemberListResponseDTO 
     * @throws ApiError
     */
    public static async listAllUserAccountBasedOnHierarchyExport(
accountOid: string,
): Promise<UserMemberListResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/user-member/list-export/${accountOid}`,
        });
        return result.body;
    }

    public static async getAllUserAccountBasedOnHierarchy(): Promise<UserMemberListResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/user-member/list`,
        });
        return result.body;
    }
            
}