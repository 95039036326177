/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import {
  QualificationDTO,
  QualificationListQuery,
  QualificationListResponse,
  CreateQualificationDto
} from '../models/QualificationSetupDto';
import { request as __request } from '@openapi/request';

export class QualificationSetupService {

  /**
   * @returns QualificationListResponse
   * @throws ApiError
   * @param query
   */
  public static async getListQualificationSetup(
    query: QualificationListQuery
  ): Promise<QualificationListResponse> {
    const result = await __request({
      method: 'GET',
      path: `/web/api/qualification-setup?type=${query.type}&page=${query.page}&limit=${query.limit}`,
    });
    return result.body;
  }

  /**
   * @param requestBody
   * @returns QualificationDTO
   * @throws ApiError
   */
  public static async createQualificationSetup(
    requestBody: CreateQualificationDto,
  ): Promise<QualificationDTO> {
    const result = await __request({
      method: 'POST',
      path: `/web/api/qualification-setup`,
      body: requestBody,
    });
    return result.body;
  }

  /**
   * @param oid
   * @param requestBody
   * @returns QualificationDTO
   * @throws ApiError
   */
  public static async updateQualification(
    oid: string,
    requestBody: CreateQualificationDto,
  ): Promise<QualificationDTO> {
    const result = await __request({
      method: 'PUT',
      path: `/web/api/qualification-setup/${oid}`,
      body: requestBody,
    });
    return result.body;
  }

  /**
   * @param oid
   * @returns QualificationDTO
   * @throws ApiError
   */
  public static async getQualificationSetup(
    oid: string,
  ): Promise<QualificationDTO> {
    const result = await __request({
      method: 'GET',
      path: `/web/api/qualification-setup/${oid}`,
    });
    return result.body;
  }

  /**
   * @returns any
   * @throws ApiError
   * @param oid
   */
  public static async deleteQualificationSetup(
    oid: string,
  ): Promise<any> {
    const result = await __request({
      method: 'DELETE',
      path: `/web/api/qualification-setup/${oid}`,
    });
    return result.body;
  }

}
