/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OktaLogoutDTO } from '../models/OktaLogoutDTO';
import type { OktaRefreshTokenResponseDTO } from '../models/OktaRefreshTokenResponseDTO';
import { request as __request } from '@openapi/request';

export class OktaService {

    /**
     * @param code
     * @returns any
     * @throws ApiError
     */
    public static async getTokenAndTenantRedirectionUrl(
        code: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/authorization-code/callback`,
            query: {
                'code': code,
            },
        });
        return result.body;
    }

    /**
     * @param code
     * @returns OktaRefreshTokenResponseDTO
     * @throws ApiError
     */
    public static async refreshOktaToken(
        code: string,
    ): Promise<OktaRefreshTokenResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/refresh-token`,
            query: {
                'code': code,
            },
        });
        return result.body;
    }

    
    /**
     * @param refreshToken
     * @returns any
     * @throws ApiError
     */
    public static async refreshToken(
        refreshToken: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/token`,
            body: {
                'refresh_token': refreshToken,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody Endpoint to invalidate token
     * @returns any
     * @throws ApiError
     */
    public static async logout(
        requestBody: OktaLogoutDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/logout`,
            body: requestBody,
        });
        return result.body;
    }

}