import { ClientBase } from '@master/model/ClientBase';
import { MaxChar } from '@master/annotation/MaxChar';
import { InputMaxChar } from '@master/enum/MaxCharsEnum';

export default class ClientTrustedIndividual extends ClientBase {

  TIaccompanied: 'yes' | 'no' | '' = '';

  TIconfirmSelectedClient?: boolean = false;
  TIpermitTrustedIndividual?: boolean = false;
  TIconfirmAppointedTI?: boolean = false;

  @MaxChar(InputMaxChar.XSMALL)
  TIconfirmLanguage = '';

  @MaxChar(InputMaxChar.XSMALL)
  TIname = '';

  @MaxChar(InputMaxChar.XSMALL)
  TIidentityType = '';

  @MaxChar(InputMaxChar.XSMALL)
  TInric = '';

  TIdob?: number = undefined;

  TIcountryOfBirth = '';

  @MaxChar(InputMaxChar.XSMALL)
  TIrelationship = '';

  @MaxChar(InputMaxChar.XSMALL)
  TIcontactMobile = '';

  TIcontactMobileCountryCode = '';
  
  @MaxChar(InputMaxChar.SMALL)
  TIemail = ''

  TIdeclinedReason = '';

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(type)
  }
}
