import { SharedVariable } from "@core/model/SharedVariable";
import { UIService } from "@core/services/UIService";
import { cid, container } from "inversify-props";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { QualificationSetupService } from "../api";
const sharedVariable = container.get<SharedVariable>(cid.SharedVariable);
const uiService = sharedVariable.uiService
  ? sharedVariable.uiService
  : new UIService();

@Module({ namespaced: true, name: "QualificationSetupStore" })
export default class QualificationSetupStore extends VuexModule {
  loading = false;
  mdrtList = [];
  cotList = [];
  totList = [];
  mdrtRecordsTotal = 0;
  cotRecordsTotal = 0;
  totRecordsTotal = 0;
  setupData = null;

  @Action({ rawError: true })
  async fetchQualificationList({ page, limit, type, mutation }) {
    try {
      const setupData =
        await QualificationSetupService.getListQualificationSetup({
          page,
          limit,
          type,
        });
      this.context.commit(mutation, setupData);
    } catch (error) {
      await uiService.alert.show({
        type: "error",
        message: error,
      });
    }
  }

  @Action({ rawError: true })
  async createSetup(setup) {
    try {
      const response = await QualificationSetupService.createQualificationSetup(
        setup
      );
      this.context.commit("setSetupData", response);
      if (response) {
        await uiService.alert.show({
          type: "success",
          message: "Data saved successfully",
        });
      }
    } catch (error) {
      await uiService.alert.show({
        type: "error",
        message: error,
      });
    }
  }

  @Action({ rawError: true })
  async updateSetup(setup) {
    try {
      const oid = setup.oid;
      ["oid", "pky", "ctm", "utm"].forEach((key) => delete setup[key]);

      const response = await QualificationSetupService.updateQualification(
        oid,
        setup
      );
      this.context.commit("setSetupData", response);
      if (response) {
        await uiService.alert.show({
          type: "success",
          message: "Data saved successfully",
        });
      }
    } catch (error) {
      await uiService.alert.show({
        type: "error",
        message: error,
      });
    }
  }

  @Mutation
  setMdrtList({ data, total }) {
    this.mdrtList = data;
    this.mdrtRecordsTotal = total;
  }

  @Mutation
  setCotList({ data, total }) {
    this.cotList = data;
    this.cotRecordsTotal = total;
  }

  @Mutation
  setTotList({ data, total }) {
    this.totList = data;
    this.totRecordsTotal = total;
  }

  @Mutation
  setSetupData(data) {
    this.setupData = data;
  }
}
