import {
  pv,
  sum,
  valueIsUndefined,
  infAdjReturn,
  fv
} from "@master/model/kyc-form/need-analysis/math";
import { IProjectSettings } from "@master/config/IProjectSettings";
import { isUndefined } from "lodash/fp";
import { Ignore } from "@master/annotation/Ignore";
import NeedsBase from "@master/model/kyc-form/need-analysis/NeedsBase";
import KYCClient from "@company-common/model/kyc-form/KYCClient";
import { NA_ERROR_MSG, Global } from "../utils/constants";
import { isEmpty } from "class-validator";

/**
 * Depending on the structure of the PDF, additionalNotes may not be in used
 */
export default class RetirementModel extends NeedsBase {
  @Ignore()
  projectSettings: IProjectSettings;

  @Ignore()
  selectedClient?: KYCClient = undefined;

  name = "Planning for retirement";
  needs = "retirement";
  isProtection = true;
  //fundRequiredTotal?: number = undefined;
  desiredReAge?: number = undefined;
  // noOfYearsRe?: number = undefined;
  desiredMonthlyReIncome?: number = undefined;
  cacheDesiredMonthlyReIncome?: number = undefined;
  //desiredAnnualReIncomePV?:number=undefined
  assumedInfRate?: number = undefined;
  //desiredAnnualReIncomeFV?: number = undefined;
  invRate?: number = undefined;
  //InfAdjInvRate?: number = undefined;
  numOfYearsForRetirement?: number = undefined;
  //remainingLiabilitiesTotal?: number = undefined;
  remainingliabilitiesRe?: number = undefined;
  //fundAvailableAtRe?: number = undefined;
  licROI?: number = undefined;
  licValue?: number = undefined;
  retirementCashAssetsROI?: number = undefined;
  retirementCashAssets?: number = undefined;
  cachedCashAssets?: number = undefined;
  retirementInvestmentsROI?: number = undefined;
  retirementInvestments?: number = undefined;
  cachedInvestments?: number = undefined;
  othersROI?: number = undefined;
  others?: string = undefined;
  othersAmount?: number = undefined;
  cacheNoOfYearsRe?:number = undefined
  // Justification model
  fundRequiredTotalReason?: string = undefined;
  desiredReAgeReason?: string = undefined;
  noOfYearsReReason?: string = undefined;
  desiredMonthlyReIncomeReason?: string = undefined;
  desiredAnnualReIncomePVReason?: string = undefined;
  assumedInfRateReason?: string = undefined;
  desiredAnnualReIncomeFVReason?: string = undefined;
  invRateReason?: string = undefined;
  InfAdjInvRateReason?: string = undefined;
  numOfYearsForRetirementReason?: string = undefined;
  remainingLiabilitiesTotalReason?: string = undefined;
  remainingliabilitiesReReason?: string = undefined;
  fundAvailableAtReReason?: string = undefined;
  licROIReason?: string = undefined;
  licValueReason?: string = undefined;
  cashAssetsROIReason?: string = undefined;
  retirementInvestmentsReason?:string = undefined
  retirementInvestmentsCR?:boolean = undefined
  cachedretirementInvestments?: number = undefined
  // cashAssetsReason?: string = undefined;
  // investmentsROIReason?: string = undefined;
  investmentsReason?: string = undefined;
  othersROIReason?: string = undefined;
  othersReason?: string = undefined;
  othersAmountReason?: string = undefined;
  
  cachedDesiredReAge?: number = undefined;
  cachedDesiredMonthlyReIncome?: number = undefined;
  cachedAssumedInfRate?: number = undefined;
  cachedInvRate?: number = undefined;
  cachedInfAdjInvRate?: number = undefined;
  cachedNumOfYearsForRetirement?: number = undefined;
  cachedLicValue?: number = undefined;
  
  desiredReAgeCR?:boolean = undefined;
  desiredMonthlyReIncomeCR?:boolean = undefined;
  assumedInfRateCR?:boolean = undefined;
  invRateCR?:boolean = undefined;
  infAdjInvRateCR?:boolean = undefined;
  numOfYearsForRetirementCR?:boolean = undefined;
  licValueCR?:boolean = undefined;
  cachedLicValueAuto?: number = undefined;
  STATUTORY_RETIRE_AGE?: number = Global?.STATUTORY_RETIRE_AGE;
  
  get noOfYearsRe () {
    if (!this.selectedClient) return this.cacheNoOfYearsRe;
    const selectedClientByPersonalDetails= this.selectedClient?.personalDetails;
    // Extract the year from the date of birth
    const birthYear = new Date(selectedClientByPersonalDetails?.dob).getFullYear();
    // Get the current year
    const currentYear = new Date().getFullYear();
    // Calculate the client's age
    const clientAge = currentYear - birthYear;
    // Calculate the number of years required
    this.cacheNoOfYearsRe = this.desiredReAge - clientAge
    return this.cacheNoOfYearsRe;
  }

  get desiredAnnualReIncomePV() {
    if (valueIsUndefined(this.desiredMonthlyReIncome)) return null;
    return 12 * this.desiredMonthlyReIncome || 0;
  }

  get desiredAnnualReIncomeFV() {
    if (valueIsUndefined(this.desiredAnnualReIncomePV)) return null;
    const total = fv(
      this.desiredAnnualReIncomePV,
      this.noOfYearsRe,
      (this.assumedInfRate || 0) / 100
    );
    if (valueIsUndefined(total)) return null;
    return parseFloat(total.toFixed(2));
  }

  get InfAdjInvRate() {
    if (valueIsUndefined(this.invRate) && valueIsUndefined(this.assumedInfRate))
      return null;
    return parseFloat(infAdjReturn(this.invRate, this.assumedInfRate)?.toFixed(2)) || 0;
  }

  get fundRequiredTotal() {
    if (
      valueIsUndefined(this.desiredAnnualReIncomeFV) &&
      valueIsUndefined(this.InfAdjInvRate)
    )
      return null;
    const total = pv(
      this.desiredAnnualReIncomeFV,
      this.numOfYearsForRetirement,
      this.InfAdjInvRate
    );
    return total.toFixed(2);
  }
  get remainingLiabilitiesTotal() {
    if (
      valueIsUndefined(this.remainingliabilitiesRe) 
    )
      return null;
    return this.remainingliabilitiesRe;
  }
  // Helper function to calculate ROI over multiple years
  calculateROI(value, roi, years) {
    if (
      roi === undefined ||
      roi === null ||
      isNaN(roi) ||
      years === undefined ||
      years === null ||
      isNaN(years)
    ) {
      return value; // If ROI or years are invalid, return the base value
    }

    let total = value;
    for (let i = 0; i < years; i++) {
      total += (roi / 100) * total;
    }
    return total;
  }
  // Helper function to check if a value is a defined number
  isDefinedNumber(value) {
    return value !== undefined && value !== null && !isNaN(value);
  }
  sum(...values) {
    return values.reduce((acc, val) => acc + (val || 0), 0);
  }
  get fundAvailableAtRe() {

    // new calc
    let total_LIC = this.licValue || 0
    let total_CashAssets = this.retirementCashAssets || 0
    let total_Investments = this.retirementInvestments || 0
    let total_Others = this.othersAmount || 0

    let total = 0;
    if (!(this.licROI === 0 || valueIsUndefined(this.licROI))) {
      total_LIC = this.calculateROI(this.licValue, this.licROI, this.noOfYearsRe)
    }
    if (!(this.retirementCashAssetsROI === 0 || valueIsUndefined(this.retirementCashAssetsROI))) {
      total_CashAssets = this.calculateROI(this.retirementCashAssets, this.retirementCashAssetsROI, this.noOfYearsRe)
    }
    if (!(this.retirementInvestmentsROI === 0 || valueIsUndefined(this.retirementInvestmentsROI))) {
      total_Investments = this.calculateROI(this.retirementInvestments, this.retirementInvestmentsROI, this.noOfYearsRe) 
    }
    if (!(this.othersROI === 0 || valueIsUndefined(this.othersROI))) {
      total_Others = this.calculateROI(this.othersAmount, this.othersROI, this.noOfYearsRe)
    }
    total = sum(total_LIC, total_CashAssets, total_Investments, total_Others);
    return parseFloat(total?.toFixed(2));


    // TODO have to remove below funtion after QA test
    // Helper function to calculate sum

    // total fund initialization
    let totalFundAvailable = undefined;

    // Check if the ROI values are available
    // const isLicROIValueAvailable =
    //   this.isDefinedNumber(this.licValue) && this.isDefinedNumber(this.licROI);
    // const isCashAssetsROIValueAvailable =
    //   this.isDefinedNumber(this.cashAssets) &&
    //   this.isDefinedNumber(this.cashAssetsROI);
    // const isInvestmentsROIValueAvailable =
    //   this.isDefinedNumber(this.investments) &&
    //   this.isDefinedNumber(this.investmentsROI);
    // const isOthersROIValueAvailable =
    //   this.isDefinedNumber(this.othersAmount) &&
    //   this.isDefinedNumber(this.othersROI);

    // const isLicROIAvailable = this.isDefinedNumber(this.licROI);
    // const isCashAssetsROIAvailable = this.isDefinedNumber(this.cashAssetsROI);
    // const isInvestmentsROIAvailable = this.isDefinedNumber(this.investmentsROI);
    // const isOthersROIAvailable = this.isDefinedNumber(this.othersROI);

    // Calculate totals based on ROI values
    // const total_LIC = isLicROIValueAvailable
    //   ? this.calculateROI(this.licValue, this.licROI, this.noOfYearsRe)
    //   : this.licValue;
    // const total_Cash_assets = isCashAssetsROIValueAvailable
    //   ? this.calculateROI(this.cashAssets, this.cashAssetsROI, this.noOfYearsRe)
    //   : this.cashAssets;
    // const total_Investments = isInvestmentsROIValueAvailable
    //   ? this.calculateROI(
    //       this.investments,
    //       this.investmentsROI,
    //       this.noOfYearsRe
    //     )
    //   : this.investments;
    // const total_Others = isOthersROIValueAvailable
    //   ? this.calculateROI(this.othersAmount, this.othersROI, this.noOfYearsRe)
    //   : this.othersAmount;

    // Determine the total fund available based on the scenarios
    // if (
    //   isLicROIValueAvailable &&
    //   isCashAssetsROIValueAvailable &&
    //   isInvestmentsROIValueAvailable &&
    //   isOthersROIValueAvailable
    // ) {
    //   totalFundAvailable = sum(
    //     total_LIC,
    //     total_Cash_assets,
    //     total_Investments,
    //     total_Others
    //   );
    // } else if (
    //   !isLicROIAvailable &&
    //   !isCashAssetsROIAvailable &&
    //   !isInvestmentsROIAvailable &&
    //   !isOthersROIAvailable
    // ) {
    //   totalFundAvailable = sum(
    //     this.licValue,
    //     this.cashAssets,
    //     this.investments,
    //     this.othersAmount
    //   );
    // } else {
    //   totalFundAvailable = sum(
    //     total_LIC,
    //     total_Cash_assets,
    //     total_Investments,
    //     total_Others
    //   );
    // }

    return totalFundAvailable && totalFundAvailable>0 ? totalFundAvailable.toFixed(2): null;
  }

  get desiredReAgeReasonStatus() {
    let isNeedReason = false;
    if (this.desiredReAge !== this.STATUTORY_RETIRE_AGE) {
      isNeedReason = true;
      if (this.desiredReAge !== this.cachedDesiredReAge) {
        this.cachedDesiredReAge = this.desiredReAge
        this.desiredReAgeCR = false
      }
    } 
    // else if (this.desiredReAgeReason !== undefined) {
    //   this.desiredReAgeReason = undefined
    //   this.cachedDesiredReAge = this.desiredReAge
    //   this.desiredReAgeCR = false
    // }
    return isNeedReason;
  }

  get desiredMonthlyReIncomeReasonStatus() {
    if (!this.selectedClient) return;
    let isNeedReason = false;
    const selectedClientByCashFlow = this.selectedClient?.cashFlow;
    if (selectedClientByCashFlow?.isUserDeclinedCashFlow === true) {
      this.desiredMonthlyReIncomeReason = undefined;
      this.desiredMonthlyReIncomeCR = false;
      return false;
    }
    const higherSimplifiedTotalDesiredMonthlyAmount =
      Math.max(
        selectedClientByCashFlow?.simplifiedTotalInflow,
        selectedClientByCashFlow?.simplifiedTotalOutflow
      ) || 0;
    const higherComprehensiveTotalDesiredMonthlyAmount =
      Math.max(
        selectedClientByCashFlow?.totalMonthlyOutflow,
        selectedClientByCashFlow?.netEmploymentIncome
      ) || 0;
    const newDesiredMonthlyReIncome = selectedClientByCashFlow?.isSimplifiedMode
      ? higherSimplifiedTotalDesiredMonthlyAmount
      : higherComprehensiveTotalDesiredMonthlyAmount;
    // !this.cacheDesiredMonthlyReIncome &&
    //   this.desiredMonthlyReIncome === undefined &&
    //   (this.desiredMonthlyReIncome = newDesiredMonthlyReIncome);
    // this.cacheDesiredMonthlyReIncome = newDesiredMonthlyReIncome;

    if (newDesiredMonthlyReIncome < this.desiredMonthlyReIncome) {
      isNeedReason = true;
      if (this.desiredMonthlyReIncome !== this.cachedDesiredMonthlyReIncome) {
        this.cachedDesiredMonthlyReIncome = this.desiredMonthlyReIncome
        this.desiredMonthlyReIncomeCR = false
      }
    } else if (this.desiredMonthlyReIncomeReason !== undefined) {
      this.desiredMonthlyReIncomeReason = undefined;
      this.cachedDesiredMonthlyReIncome = this.desiredMonthlyReIncome
      this.desiredMonthlyReIncomeCR = false;
    }

    return isNeedReason;
  }

  get assumedInfRateReasonStatus() {
    if (!this.selectedClient) return;
    let isNeedReason = false;

    if (this.assumedInfRate < 0 || this.assumedInfRate > 9) {
      isNeedReason = true;
      if (this.assumedInfRate !== this.cachedAssumedInfRate) {
        this.cachedAssumedInfRate = this.assumedInfRate
        this.assumedInfRateCR = false
      }
    } else if (this.assumedInfRateReason !== undefined) {
      this.assumedInfRateReason = undefined;
      this.assumedInfRateCR = false;
      this.cachedAssumedInfRate = this.assumedInfRate
    }
    return isNeedReason;
  }

  get invRateReasonStatus() {
    if (!this.selectedClient) return;
    let isNeedReason = false;

    if (this.invRate < 0 || this.invRate > 9) {
      isNeedReason = true;
      if (this.invRate !== this.cachedInvRate) {
        this.cachedInvRate = this.invRate
        this.invRateCR = false
      }
    } else if (this.invRateReason !== undefined) {
      this.invRateReason = undefined;
      this.invRateCR = false;
      this.cachedAssumedInfRate = this.invRate
    }
    return isNeedReason;
  }
  get InfAdjInvRateReasonStatus() {
    if (!this.selectedClient) return;
    let isNeedReason = false;

    if (this.InfAdjInvRate < -2 || this.InfAdjInvRate > 9) {
      isNeedReason = true;
      if (this.InfAdjInvRate !== this.cachedInfAdjInvRate) {
        this.cachedInfAdjInvRate = this.InfAdjInvRate
        this.infAdjInvRateCR = false
      }
    } else if (this.invRateReason !== undefined) {
      this.InfAdjInvRateReason = undefined;
      this.infAdjInvRateCR = false;
      this.cachedInfAdjInvRate = this.InfAdjInvRate
    }
    return isNeedReason;
  }

  get numOfYearsForRetirementReasonStatus() {
    if (!this.selectedClient) return;
    const selectedClientByPersonalDetails =
      this.selectedClient?.personalDetails;
    let isNeedReason = false;
    const lifeExpectancy =
      selectedClientByPersonalDetails?.gender === "male"
        ? Global.LIFE_EXPECT_MALE
        : Global.LIFE_EXPECT_FEMALE;

    const cacheCalcValue = lifeExpectancy - this.STATUTORY_RETIRE_AGE;
    const calcValue = lifeExpectancy - this.desiredReAge;
    
    if (this.numOfYearsForRetirement !== calcValue) {
      isNeedReason = true;
      if (this.numOfYearsForRetirement !== this.cachedNumOfYearsForRetirement) {
        this.cachedNumOfYearsForRetirement = this.numOfYearsForRetirement
        this.numOfYearsForRetirementCR = false
      }
    } else if ( this.numOfYearsForRetirementReason !== undefined) {
      this.numOfYearsForRetirementReason = undefined;
      this.numOfYearsForRetirementCR = true;
      this.cachedNumOfYearsForRetirement = this.numOfYearsForRetirement
    }
    // this.numOfYearsForRetirement = calcValue;
    return isNeedReason;
  }
  get licValueReasonStatus() {
    if (!this.selectedClient) return;
    let isNeedReason = false;
    const selectedClientByExInsurance = this.selectedClient?.existingInsurence;
    let calcTotal = 0;
    // Sum cash value/ maturity in client's insuranceRecords
    if (selectedClientByExInsurance?.insuranceRecords) {
      calcTotal += selectedClientByExInsurance.insuranceRecords.reduce(
        (sum, record) => sum + (record.cashValue || 0),
        0
      );
    }

    if (this.licValue < calcTotal && (!isEmpty(this.licValue) && !isEmpty(calcTotal))) {
      isNeedReason = true;
      if (this.licValue !== this.cachedLicValue) {
        this.cachedLicValue = this.licValue
        this.licValueCR = false
      }
    } else if (this.licValueReason !== undefined) {
      this.licValueReason = undefined;
      this.licValueCR = undefined;
      this.cachedLicValue = this.licValue
    }

    return isNeedReason;
  }

  get licValueError() {
    if (!this.selectedClient) return;
    const selectedClientByExInsurance = this.selectedClient?.existingInsurence;
    let calcTotal = 0;
    // Sum cash value/ maturity in client's insuranceRecords
    if (
      selectedClientByExInsurance?.insuranceRecords &&
      selectedClientByExInsurance?.discloseExistingInsurance !== true
    ) {
      calcTotal += selectedClientByExInsurance.insuranceRecords.reduce(
        (sum, record) => sum + (record.cashValue || 0),
        0
      );
    }

    if (this.licValue > calcTotal) {
      return NA_ERROR_MSG.TB;
    } else {
      return null;
    }
  }

  // get cashAssetsReasonStatus() {
  //   if (!this.selectedClient) return;
  //   let isNeedReason = false;
  //   const selectedClientByNetWorth = this.selectedClient?.netWorth;

  //   const calcTotal = selectedClientByNetWorth?.isSimplifiedMode
  //     ? selectedClientByNetWorth?.simplifiedTotalCash
  //     : sum(
  //         selectedClientByNetWorth?.savings,
  //         selectedClientByNetWorth?.fixedDeposits,
  //         selectedClientByNetWorth?.cashNearCashOthersAmount
  //       ) || 0;

  //   if (this.cashAssets !== calcTotal) {
  //     isNeedReason = true;
  //   } else {
  //     this.cashAssetsReason = undefined;
  //   }

  //   return isNeedReason;
  // }

  // get investmentsReasonStatus() {
  //   if (!this.selectedClient) return;
  //   let isNeedReason = false;
  //   const selectedClientByNetWorth = this.selectedClient?.netWorth;

  //   if (selectedClientByNetWorth?.isSimplifiedMode) {
  //     this.investmentsReason = undefined;
  //     return false;
  //   }

  //   const calcTotal = selectedClientByNetWorth?.isUserDeclinedAssetsFlow === true ? 0 : 
  //     sum(
  //       selectedClientByNetWorth?.unitTrusts,
  //       selectedClientByNetWorth?.stocks,
  //       selectedClientByNetWorth?.bonds
  //     ) || 0;

  //   if (this.investments !== calcTotal) {
  //     isNeedReason = true;
  //   } else {
  //     this.investmentsReason = undefined;
  //   }

  //   return isNeedReason;
  // }
  get grandTotal() {
    const total = this.fundAvailableAtRe - sum(
      this.fundRequiredTotal,
      this.remainingLiabilitiesTotal,
    )
    return parseFloat(total.toFixed(2))
  }

  get retirementInvestmentsReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false
    const selectedClientByNetWorth= this.selectedClient?.netWorth

    if(selectedClientByNetWorth?.isSimplifiedMode) {
      this.retirementInvestmentsReason = undefined
      this.retirementInvestmentsCR = false
      return false
    }
      
    const calcTotal = sum(selectedClientByNetWorth?.unitTrusts,
      selectedClientByNetWorth?.stocks,
      selectedClientByNetWorth?.bonds,
    ) || 0
    
    if (this.retirementInvestments !== calcTotal && (!isEmpty(this.retirementInvestments) && !isEmpty(calcTotal)) ){
      isNeedReason = true
      if (this.retirementInvestments !== this.cachedretirementInvestments) {
        this.retirementInvestmentsReason = undefined
        this.cachedretirementInvestments = this.retirementInvestments
        this.retirementInvestmentsCR = false
      }
    } else {
      this.retirementInvestmentsReason = undefined
      this.cachedretirementInvestments = this.retirementInvestments
      this.retirementInvestmentsCR = false
    }

    return isNeedReason
  }

  get isCompleted() {
    
    const isRequired = this.desiredReAge && this.desiredMonthlyReIncome != null && this.assumedInfRate != null 
    && this.invRate != null && this.numOfYearsForRetirement != null;

    const clearOtherMandatory = !!this.others || this.othersAmount!= null
    let allOthersFilled = true
    if (clearOtherMandatory) allOthersFilled = !!this.others && this.othersAmount!= null
    const reasonCheck = 
    (this.desiredReAgeReasonStatus && !this.desiredReAgeReason) ||
    (this.desiredMonthlyReIncomeReasonStatus && !this.desiredMonthlyReIncomeReason) ||
    (this.assumedInfRateReasonStatus && !this.assumedInfRateReason) ||
    (this.invRateReasonStatus && !this.invRateReason) ||
    (this.InfAdjInvRateReasonStatus && !this.InfAdjInvRateReason) ||
    (this.numOfYearsForRetirementReasonStatus && !this.numOfYearsForRetirementReason) ||
    (this.licValueReasonStatus && !this.licValueReason) ||
    ((this.retirementInvestmentsReasonStatus && !this.retirementInvestmentsReason) || (this.retirementInvestmentsReason && !this.retirementInvestmentsCR)) || 
    // (this.cashAssetsReasonStatus && !this.cashAssetsReason) ||
    // (this.investmentsReasonStatus && !this.investmentsReason) ||
    this.licValueError;


    return isRequired && allOthersFilled && !reasonCheck;
  }

  set setSelectedClient(client) {
    this.selectedClient = client;
  }
//   set setTenantConfig(tenatConfigs) {
//     console.log(tenatConfigs, )
//     this.tenatConfigs = tenatConfigs;
//   }
}
