import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"z-index":"999"},
  class: "change_color"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppDynamicHeader = _resolveComponent("AppDynamicHeader")!
  const _component_AppDynamicIconHeader = _resolveComponent("AppDynamicIconHeader")!
  const _component_AlertPopup = _resolveComponent("AlertPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isShowHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.isModernTheme)
            ? (_openBlock(), _createBlock(_component_AppDynamicHeader, {
                key: 0,
                onLogout: _ctx.logout,
                header: _ctx.headerStructure
              }, null, 8, ["onLogout", "header"]))
            : (_openBlock(), _createBlock(_component_AppDynamicIconHeader, {
                key: 1,
                mini: true,
                onLogout: _ctx.logout,
                header: _ctx.headerStructure
              }, null, 8, ["onLogout", "header"]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.logOutAlert)
      ? (_openBlock(), _createBlock(_component_AlertPopup, {
          key: 1,
          showModal: _ctx.logOutAlert,
          class: "session-alert",
          title: `Are you still there ?`,
          message: `You’ve been idle and will be automatically logged out in ${_ctx.formattedCountdownTime}`,
          btnText: "Log out",
          actionBtnText: "Yes, I’m here",
          type: "warning",
          showTwoButton: true,
          onClose: _ctx.handleSessionExpiration,
          onAction: _ctx.resetSessionTimer
        }, null, 8, ["showModal", "message", "onClose", "onAction"]))
      : _createCommentVNode("", true)
  ], 64))
}