import { Base } from '../Base';
import { Default } from '@master/annotation/Default';

export class InsuranceProductArray extends Base {
  insuranceProduct: InsuranceProduct[] = []
  client1ApplicationReq: any[] = []
  client2ApplicationReq: any[] = []
  client1ApplicationRes: any[] = []
  client2ApplicationRes: any[] = []
}

export class InsuranceProduct extends Base {
  @Default(null)
  oid: string;

  @Default(null)
  ezSubQuotation: any

  @Default(false)
  isClientChoice: boolean = false

  @Default(false)
  isSubmitted: boolean = false

  // Common - Proposer
  @Default(null)
  proposer?: string;

  // Common - Life Assured
  @Default(null)
  lifeAssured?: string;

  // Common - eFNA Life Assured (for comparing with life assured from ezsub product)
  @Default(null)
  efnaLifeAssured?: string;

  // Common - Needs
  @Default([])
  needs: string[];

  // Common - Needs
  @Default([])
  selectedNeeds: string[];

  @Default(null)
  addProductDetails: 'ezsub' | 'manually';

  // Manually - Product details - Company
  @Default(null)
  company: any;

  // Manually - Product details - Product Category
  @Default(null)
  productCategory: any;

  // Manually - Product details - Client's Risk profile
  @Default(null)
  clientRiskProfile: any;

  // Manually - Product details - Client's Risk profile object data
  @Default(null)
  clientRiskProfileObj?: any;

  // Manually - Product details - Product Name
  @Default(null)
  productName: any;

  @Default(null)
  productNameObj: any;

  // Manually - Product details - Product Name additional (if productName has indicator equals to "Insurance - FamilyTag")
  @Default(null)
  productNameAdditional: any;
  
  @Default(null)
  productNameAdditionalObj: any;

  // Manually - Product details - Add rider only
  @Default(false)
  addRiderOnly: boolean;

  // Manually - Product details - Sum Assured
  @Default(null)
  sumAssured: string;

  // Manually - Product details - Payment Frequency
  @Default(null)
  paymentFrequency: any;

  // Manually - Product details - Premium
  @Default(null)
  premium: number;

  // Manually - Product details - Premium Term
  @Default(null)
  premiumTerm: string;

  // Manually - Product details - Policy Term
  @Default(null)
  policyTerm: string;

  // Manually - Product details - Settlement Mode
  @Default(null)
  settlementMode: any;

  // Manually - Product details - CPF-MA Premium
  @Default(null)
  cpfMaPremium: number;

  // Manually - Product details - Cash Premium Top Up
  @Default(null)
  cashPremiumTopUp: number;

  // Manually - Multiplier - Add multiplier/additional cover
  @Default(false)
  addMultiplier: boolean;

  // Manually - Multiplier - Per Policy Illustration
  @Default(null)
  multiplierPerPolicyIllustration: any;

  // Manually - Multiplier - Sum Assured Unit
  @Default(null)
  multiplierSumAssuredUnit: string;

  // Manually - Multiplier - Sum Assured Value
  @Default(null)
  multiplierSumAssuredValue: number;

  // Manually - Multiplier - Premium
  @Default(null)
  multiplierPremium: number;

  // Manually - Multiplier - Premium Term
  @Default(null)
  multiplierPremiumTerm: string;

  // Manually - Multiplier - Policy Term
  @Default(null)
  multiplierPolicyTerm: string;

  // Rider Details
  @Default([])
  riderDetails: RiderProduct[] = []

  // Fund Details
  @Default([])
  fundDetails: FundDetail[] = []

  timeHorizonError?:boolean = false;

  // Product details - productNeeds
  @Default("")
  productNeeds?: string;

  @Default("")
  borReason?: string;

  @Default(true)
  borPointer?:boolean = true;

  @Default("")
  borReasonLastEdited?: string;

  @Default(null)
  buySwitch

  @Default("")
  borSwitchingPolicyJustification

  @Default("")
  borNeedsAdditionalInfo

  @Default("")
  borRiskProfileAdditionalInfo

  @Default("")
  borBudgetAdditionalInfo

  @Default("")
  borProdFeatures

  @Default("")
  borProdRisks
  
  @Default("")
  borProdLimitations

  @Default("")
  borProdFeeCharges

  @Default("")
  borFundDetailsLastEdited

  @Default("")
  borFundRisks

  @Default(false)
  validationError: boolean;

  constructor(params?: Partial<InsuranceProduct>) {
    super()
    Object.assign(this, params);
  }
}

export class FundDetail {
  oid: string;

  @Default(null)
  fundNameOid?: string;

  @Default(null)
  fundName?: string;

  @Default(null)
  allocation?: number;

  @Default(null)
  assetClass?: string;

  @Default(null)
  riskRate?: string;

  @Default(null)
  riskClassification?: string;

  @Default(null)
  geographicalFocus?: string;

  @Default(null)
  borFundDetails?: string;

  @Default("")
  borFundRisks

  @Default("")
  borAddInput

  constructor(params: Partial<FundDetail>) {
    Object.assign(this, params);
  }
}

export class RiderProduct {
  oid?: string;

  @Default(null)
  riderName?: string;

  @Default(null)
  riderNameObj: any;

  @Default(null)
  riderSumAssured?: string;

  @Default(null)
  riderPremium?: string;

  @Default(null)
  riderPremiumTerm?: string;

  @Default(null)
  riderCoverageTerm?: string;

  @Default(null)
  ppi?: string;

  @Default(null)
  additionalSumAssured?: string;

  sumAssurredNumber
  sumAssurredSymbol

  @Default(false)
  isMultipleAdditional?:boolean

  @Default(null)
  additionalPremium?: string;

  @Default(null)
  additionalPremiumTerm?: string;

  @Default(null)
  additionalPolicyTerm?: string;

  // Product details - productNeeds
  @Default("")
  riderProductNeeds?: string;
  
  @Default("")
  borProdFeatures

  @Default("")
  borProdRisks
  
  @Default("")
  borProdLimitations

  @Default("")
  borProdFeeCharges

  constructor(params: Partial<RiderProduct>) {
    Object.assign(this, params);
  }
}
