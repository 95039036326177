/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdviserReviewDeleteRequestDTO } from '../models/AdviserReviewDeleteRequestDTO';
import type { AdviserReviewUpdateRequestDTO } from '../models/AdviserReviewUpdateRequestDTO';
import type { BscAdviserGradeDto } from '../models/BscAdviserGradeDto';
import type { BscAdviserGradeResponseDto } from '../models/BscAdviserGradeResponseDto';
import type { BscAgentFindingRequestDTO } from '../models/BscAgentFindingRequestDTO';
import type { BscBaseRecordRequestDTO } from '../models/BscBaseRecordRequestDTO';
import type { BscBaseRecordResponseDto } from '../models/BscBaseRecordResponseDto';
import type { BscBaseRecordUpdateRequestDTO } from '../models/BscBaseRecordUpdateRequestDTO';
import type { BscCycleExecutionResponseDto } from '../models/BscCycleExecutionResponseDto';
import type { BscGradeImportSummaryDto } from '../models/BscGradeImportSummaryDto';
import type { BscGradeImportSummaryRequestDto } from '../models/BscGradeImportSummaryRequestDto';
import type { BscGradeImportSummaryResponseDto } from '../models/BscGradeImportSummaryResponseDto';
import type { BscInscopeProductDTO } from '../models/BscInscopeProductDTO';
import type { BscInscopeRequestBody } from '../models/BscInscopeRequestBody';
import type { BscPolicyInfractionCreationDTO } from '../models/BscPolicyInfractionCreationDTO';
import type { BscPolicyInfractionDTO } from '../models/BscPolicyInfractionDTO';
import type { BscPolicyInfractionListResponseDTO } from '../models/BscPolicyInfractionListResponseDTO';
import type { BscPolicyInfractionUpdateDTO } from '../models/BscPolicyInfractionUpdateDTO';
import type { BscPreviousDto } from '../models/BscPreviousDto';
import type { BscQuarterDTO } from '../models/BscQuarterDTO';
import type { BscQuarterListResponseDTO } from '../models/BscQuarterListResponseDTO';
import type { BscQuarterNewRequestDTO } from '../models/BscQuarterNewRequestDTO';
import type { BscQuarterUpdateRequestDTO } from '../models/BscQuarterUpdateRequestDTO';
import type { BscQuarterWithUserReviewDTO } from '../models/BscQuarterWithUserReviewDTO';
import type { BscReportSetupDto } from '../models/BscReportSetupDto';
import type { BscReportSetupResponseDto } from '../models/BscReportSetupResponseDto';
import type { BscSupervisorGradeDto } from '../models/BscSupervisorGradeDto';
import type { BscSupervisorGradeResponseDto } from '../models/BscSupervisorGradeResponseDto';
import type { BscUserReviewQuarterDTO } from '../models/BscUserReviewQuarterDTO';
import type { BscVariablePercentageDto } from '../models/BscVariablePercentageDto';
import type { BscVariablePercentageResponseDto } from '../models/BscVariablePercentageResponseDto';
import type { CreateBscBaseRecordResponseDto } from '../models/CreateBscBaseRecordResponseDto';
import type { GenericPaginationDto } from '../models/GenericPaginationDto';
import type { GenericSuccessResponseDto } from '../models/GenericSuccessResponseDto';
import type { PublishIncomesRequestPayloadDto } from '../models/PublishIncomesRequestPayloadDto';
import { request as __request } from '@openapi/request';

export class BscService {

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async savePolicyInfraction(
requestBody: BscPolicyInfractionDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/bsc/save-meta`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns BscQuarterDTO 
     * @throws ApiError
     */
    public static async saveBscQuarterWithUsers(
requestBody: BscQuarterNewRequestDTO,
): Promise<BscQuarterDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc-review/save`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns BscQuarterDTO 
     * @throws ApiError
     */
    public static async updateBscQuarterWithUsers(
requestBody: BscQuarterUpdateRequestDTO,
): Promise<BscQuarterDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc-review/update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns BscQuarterListResponseDTO 
     * @throws ApiError
     */
    public static async retrieveBscQuarterList(): Promise<BscQuarterListResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-review/list`,
        });
        return result.body;
    }

    /**
     * @param bscReviewOid 
     * @returns BscQuarterWithUserReviewDTO 
     * @throws ApiError
     */
    public static async retrieveBscQuarterReview(
bscReviewOid: string,
): Promise<BscQuarterWithUserReviewDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-review/${bscReviewOid}`,
        });
        return result.body;
    }

    /**
     * @param bscReviewOid 
     * @param userOid 
     * @returns BscQuarterWithUserReviewDTO 
     * @throws ApiError
     */
    public static async retrieveBscQuarterReviewForUser(
bscReviewOid: string,
userOid: string,
): Promise<BscQuarterWithUserReviewDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-review/${bscReviewOid}/${userOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns BscPolicyInfractionDTO 
     * @throws ApiError
     */
    public static async savePolicyInfractionV2(
requestBody: BscPolicyInfractionCreationDTO,
): Promise<BscPolicyInfractionDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc/save-meta`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns BscPolicyInfractionDTO 
     * @throws ApiError
     */
    public static async updatePolicyInfractionV2(
requestBody: BscPolicyInfractionUpdateDTO,
): Promise<BscPolicyInfractionDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc/update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param adviserReviewOid 
     * @returns BscPolicyInfractionListResponseDTO 
     * @throws ApiError
     */
    public static async listPolicyInfractionOfAdviserReview(
adviserReviewOid: string,
): Promise<BscPolicyInfractionListResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc/list/${adviserReviewOid}`,
        });
        return result.body;
    }

    /**
     * @param policyOid 
     * @returns BscPolicyInfractionListResponseDTO 
     * @throws ApiError
     */
    public static async listPolicyInfractionByPolicyId(
policyOid: string,
): Promise<BscPolicyInfractionListResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc/policyInfractions/list/${policyOid}`,
        });
        return result.body;
    }

    /**
     * @param infractionOid 
     * @returns BscPolicyInfractionDTO 
     * @throws ApiError
     */
    public static async retrieveInfractionDetails(
infractionOid: string,
): Promise<BscPolicyInfractionDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc/${infractionOid}`,
        });
        return result.body;
    }

    /**
     * @param reviewOid 
     * @returns any 
     * @throws ApiError
     */
    public static async generateAdviserReviewPdf(
reviewOid: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-adviser-review/generate-pdf`,
            query: {
                'reviewOid': reviewOid,
            },
        });
        return result.body;
    }

    /**
     * @param adviserReviewOid 
     * @returns BscUserReviewQuarterDTO 
     * @throws ApiError
     */
    public static async retrieveAdviserReview(
adviserReviewOid: string,
): Promise<BscUserReviewQuarterDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-adviser-review`,
            query: {
                'adviserReviewOid': adviserReviewOid,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async massDeleteAdviserReview(
requestBody: AdviserReviewDeleteRequestDTO,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/v2/bsc-adviser-review`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async updateAdviserReview(
requestBody: AdviserReviewUpdateRequestDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc-adviser-review`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param currentReviewOid 
     * @returns BscUserReviewQuarterDTO 
     * @throws ApiError
     */
    public static async retrieveLastGrade(
currentReviewOid: string,
): Promise<BscUserReviewQuarterDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-adviser-review/last-grade/${currentReviewOid}`,
        });
        return result.body;
    }

    /**
     * @param userOid 
     * @returns BscQuarterListResponseDTO 
     * @throws ApiError
     */
    public static async retrieveBscQuarterListForUser(
userOid: string,
): Promise<BscQuarterListResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-adviser-review/user/${userOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async createNewPreviousRecord(
requestBody: string,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc-previous/new`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param previousRecordOid 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async updatePreviousRecord(
previousRecordOid: string,
requestBody: BscPreviousDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc-previous/update`,
            query: {
                'previous_record_oid': previousRecordOid,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param userOid 
     * @returns any 
     * @throws ApiError
     */
    public static async getPreviousRecords(
userOid: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-previous/get`,
            query: {
                'userOid': userOid,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns BscInscopeProductDTO 
     * @throws ApiError
     */
    public static async checkProductsInscope(
requestBody: BscInscopeRequestBody,
): Promise<Array<BscInscopeProductDTO>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/bsc/check-products`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param limit 
     * @param page 
     * @param retrieveAll 
     * @returns BscVariablePercentageResponseDto 
     * @throws ApiError
     */
    public static async retrieveListOfBscVariablePercentage(
limit?: number,
page?: number,
retrieveAll?: boolean,
): Promise<BscVariablePercentageResponseDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/bsc/bsc-variable-percentage`,
            query: {
                'limit': limit,
                'page': page,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns BscVariablePercentageDto 
     * @throws ApiError
     */
    public static async createBscVariablePercentage(
requestBody: BscVariablePercentageDto,
): Promise<BscVariablePercentageDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-variable-percentage`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @param requestBody 
     * @returns BscVariablePercentageDto 
     * @throws ApiError
     */
    public static async updateBscVariablePercentage(
oid: string,
requestBody: BscVariablePercentageDto,
): Promise<BscVariablePercentageDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-variable-percentage/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns BscVariablePercentageDto 
     * @throws ApiError
     */
    public static async deleteBscVariablePercentage(
oid: string,
): Promise<BscVariablePercentageDto> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/v3/bsc/bsc-variable-percentage/${oid}`,
        });
        return result.body;
    }

    /**
     * @param limit 
     * @param page 
     * @param retrieveAll 
     * @returns BscAdviserGradeResponseDto 
     * @throws ApiError
     */
    public static async retrieveListOfBscAdviserGrade(
limit?: number,
page?: number,
retrieveAll?: boolean,
): Promise<BscAdviserGradeResponseDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/bsc/bsc-adviser-grade`,
            query: {
                'limit': limit,
                'page': page,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns BscAdviserGradeDto 
     * @throws ApiError
     */
    public static async createBscAdviserGrade(
requestBody: BscAdviserGradeDto,
): Promise<BscAdviserGradeDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-adviser-grade`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @param requestBody 
     * @returns BscAdviserGradeDto 
     * @throws ApiError
     */
    public static async updateBscAdviserGrade(
oid: string,
requestBody: BscAdviserGradeDto,
): Promise<BscAdviserGradeDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-adviser-grade/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns BscAdviserGradeDto 
     * @throws ApiError
     */
    public static async deleteBscAdviserGrade(
oid: string,
): Promise<BscAdviserGradeDto> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/v3/bsc/bsc-adviser-grade/${oid}`,
        });
        return result.body;
    }

    /**
     * @returns BscSupervisorGradeDto 
     * @throws ApiError
     */
    public static async retrieveLatestBscSupervisorGrade(): Promise<BscSupervisorGradeDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/bsc/bsc-supervisor-grade/latest`,
        });
        return result.body;
    }

    /**
     * @param limit 
     * @param page 
     * @param retrieveAll 
     * @returns BscSupervisorGradeResponseDto 
     * @throws ApiError
     */
    public static async retrieveListOfBscSupervisorGradeHistory(
limit?: number,
page?: number,
retrieveAll?: boolean,
): Promise<BscSupervisorGradeResponseDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/bsc/bsc-supervisor-grade/history`,
            query: {
                'limit': limit,
                'page': page,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns BscSupervisorGradeDto 
     * @throws ApiError
     */
    public static async createBscSupervisorGrade(
requestBody: BscSupervisorGradeDto,
): Promise<BscSupervisorGradeDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-supervisor-grade`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @param requestBody 
     * @returns BscSupervisorGradeDto 
     * @throws ApiError
     */
    public static async updateBscSupervisorGrade(
oid: string,
requestBody: BscSupervisorGradeDto,
): Promise<BscSupervisorGradeDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-supervisor-grade/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns CreateBscBaseRecordResponseDto 
     * @throws ApiError
     */
    public static async createBscBaseRecord(
requestBody: BscBaseRecordRequestDTO,
): Promise<CreateBscBaseRecordResponseDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-base-record`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns CreateBscBaseRecordResponseDto 
     * @throws ApiError
     */
    public static async getBscBaseRecordByOid(
oid: string,
): Promise<CreateBscBaseRecordResponseDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/bsc/bsc-base-record/${oid}`,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @param requestBody 
     * @returns CreateBscBaseRecordResponseDto 
     * @throws ApiError
     */
    public static async updateBscBaseRecord(
oid: string,
requestBody: BscBaseRecordUpdateRequestDTO,
): Promise<CreateBscBaseRecordResponseDto> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/v3/bsc/bsc-base-record/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns GenericSuccessResponseDto 
     * @throws ApiError
     */
    public static async deleteBscBaseRecord(
oid: string,
): Promise<GenericSuccessResponseDto> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/v3/bsc/bsc-base-record/${oid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns BscBaseRecordResponseDto 
     * @throws ApiError
     */
    public static async retrieveListOfbscBaseRecord(
requestBody: GenericPaginationDto,
): Promise<BscBaseRecordResponseDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-base-record/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns GenericSuccessResponseDto 
     * @throws ApiError
     */
    public static async publishAdviserSupervisorIncomes(
requestBody: PublishIncomesRequestPayloadDto,
): Promise<GenericSuccessResponseDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-base-record/publish`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param bscBaseRecordOid 
     * @param requestBody 
     * @returns BscGradeImportSummaryResponseDto 
     * @throws ApiError
     */
    public static async retrieveListOfBscGradeImportSummary(
bscBaseRecordOid: string,
requestBody: BscGradeImportSummaryRequestDto,
): Promise<BscGradeImportSummaryResponseDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-grade-import-summary/${bscBaseRecordOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param bscBaseRecordOid 
     * @param format 
     * @returns any Exports BSC Grade Imports as CSV
     * @throws ApiError
     */
    public static async exportBscGradeImportSummary(
bscBaseRecordOid: string,
format: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/bsc/bsc-grade-import-summary/export/${format}/${bscBaseRecordOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns BscGradeImportSummaryDto 
     * @throws ApiError
     */
    public static async createBscGradeImportSummary(
requestBody: BscGradeImportSummaryDto,
): Promise<BscGradeImportSummaryDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-grade-import-summary`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static async importBscGradeImportSummary(
        requestBody: any
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-grade-import-summary/import`,
            formData: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async deleteBscGradeImportSummary(
requestBody: Array<string>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-grade-import-summary/delete`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async updateBscGradeImportSummary(
oid: string,
requestBody: BscGradeImportSummaryDto,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/v3/bsc/bsc-grade-import-summary/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns BscGradeImportSummaryDto 
     * @throws ApiError
     */
    public static async getBscGradeImportSummaryByOid(
oid: string,
): Promise<BscGradeImportSummaryDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/bsc/bsc-grade-import-summary/preview/${oid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async createBscAgentFinding(
requestBody: BscAgentFindingRequestDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-agent-finding`,
            body: requestBody,
        });
        return result.body;
    }

   /**
     * @returns any 
     * @throws ApiError
     */
   public static async importBscAgentFinding(
    requestBody: any
): Promise<any> {
    const result = await __request({
        method: 'POST',
        path: `/web/api/v3/bsc/bsc-agent-finding/import`,
        formData: requestBody,
    });
    return result.body;
}

    /**
     * @param bscBaseRecordOid 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async retrieveListOfbscAgentFinding(
bscBaseRecordOid: string,
requestBody: GenericPaginationDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-agent-finding/list/${bscBaseRecordOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param bscBaseRecordOid 
     * @param format 
     * @returns any Exports BSC Agent Findings as CSV
     * @throws ApiError
     */
    public static async exportBscAgentFindings(
bscBaseRecordOid: string,
format: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/bsc/bsc-agent-finding/export/${format}/${bscBaseRecordOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async deleteAgentFinding(
requestBody: Array<string>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-agent-finding/delete`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async updateBscAgentFinding(
oid: string,
requestBody: BscAgentFindingRequestDTO,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/v3/bsc/bsc-agent-finding/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns BscAgentFindingRequestDTO 
     * @throws ApiError
     */
    public static async getBscAgentFindingByOid(
oid: string,
): Promise<BscAgentFindingRequestDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/bsc/bsc-agent-finding/preview/${oid}`,
        });
        return result.body;
    }

    /**
     * @param year 
     * @param quarter 
     * @param adviserCode 
     * @returns any 
     * @throws ApiError
     */
    public static async getBscAgentFindingPolicyList(
year: number,
quarter: number,
adviserCode: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/bsc/bsc-agent-finding/policy/list`,
            query: {
                'year': year,
                'quarter': quarter,
                'adviserCode': adviserCode,
            },
        });
        return result.body;
    }

    /**
     * @param adviserCode 
     * @param baseRecordOid 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async getBscAgentFindingsByAdviserCode(
adviserCode: string,
baseRecordOid: string,
requestBody: GenericPaginationDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-agent-finding/individual/${adviserCode}/${baseRecordOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns BscCycleExecutionResponseDto 
     * @throws ApiError
     */
    public static async retrieveBscCycleExecutionList(
requestBody: GenericPaginationDto,
): Promise<BscCycleExecutionResponseDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-cycle-execution/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param bscBaseRecordOid 
     * @returns any 
     * @throws ApiError
     */
    public static async runCycleExecution(
bscBaseRecordOid: string,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-cycle-execution/run-execution/${bscBaseRecordOid}`,
        });
        return result.body;
    }

    /**
     * @param baseRecordOid 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async retrieveAdviserIncomes(
baseRecordOid: string,
requestBody: GenericPaginationDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-adviser-var-income/list/${baseRecordOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param format 
     * @param baseRecordOid 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async exportAdviserIncomes(
format: string,
baseRecordOid: string,
requestBody: GenericPaginationDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-adviser-var-income/export/list/${format}/${baseRecordOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param baseRecordOid 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async retrieveSupervisorIncomes(
baseRecordOid: string,
requestBody: GenericPaginationDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-supervisor-var-income/list/${baseRecordOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param format 
     * @param baseRecordOid 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async exportSupervisorIncomes(
format: string,
baseRecordOid: string,
requestBody: GenericPaginationDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-supervisor-var-income/export/list/${format}/${baseRecordOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param limit 
     * @param page 
     * @param retrieveAll 
     * @returns BscReportSetupResponseDto 
     * @throws ApiError
     */
    public static async retrieveListOfBscVariablePercentage1(
limit?: number,
page?: number,
retrieveAll?: boolean,
): Promise<BscReportSetupResponseDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/bsc/bsc-report-setup`,
            query: {
                'limit': limit,
                'page': page,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns BscReportSetupDto 
     * @throws ApiError
     */
    public static async createBscVariablePercentage1(
requestBody: BscReportSetupDto,
): Promise<BscReportSetupDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-report-setup`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @param requestBody 
     * @returns BscReportSetupDto 
     * @throws ApiError
     */
    public static async updateBscVariablePercentage1(
oid: string,
requestBody: BscReportSetupDto,
): Promise<BscReportSetupDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-report-setup/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param baseRecordOid 
     * @param adviserCode 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async listAdvisersPublishedIncomes(
baseRecordOid: string,
adviserCode: string,
requestBody: GenericPaginationDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-advisers-profile/${baseRecordOid}/${adviserCode}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param adviserOid 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async getAdvisersPublishedGrades(
adviserOid: string,
requestBody: GenericPaginationDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/bsc/bsc-advisers-profile/${adviserOid}`,
            body: requestBody,
        });
        return result.body;
    }

        /**
     * @returns BscReportSetupResponseDto 
     * @throws ApiError
     */
        public static async retrieveListOfBscReportSetup(): Promise<BscReportSetupResponseDto> {
            const result = await __request({
                method: 'GET',
                path: `/web/api/v3/bsc/bsc-report-setup`,
            });
            return result.body;
        }
    
        /**
         * @param requestBody 
         * @returns GenericSuccessResponseDto 
         * @throws ApiError
         */
        public static async updateBscReportSetup(
    requestBody: BscReportSetupDto,
    ): Promise<GenericSuccessResponseDto> {
            const result = await __request({
                method: 'POST',
                path: `/web/api/v3/bsc/bsc-report-setup`,
                body: requestBody,
            });
            return result.body;
        }
    

}