import { UIService } from './../services/UIService'
// import { CombinedVueInstance } from 'vue/types/vue'
import { SysInfo } from './SysInfo'
import { UIOptions } from './UIOptions'

export class SharedVariable {
  // vue: CombinedVueInstance<any, object, object, object, Record<never, any>>
  vue: any
  sysInfo: SysInfo
  uiService: UIService
  options: UIOptions

  isPermissionDisabled (
    subject: string,
    access: string,
    currentPermission,
    target?: 'all' | 'self' | 'others'
  ) {
    const actionList = ['approve', 'create', 'manage', 'read', 'reject', 'return', 'submit', 'write']
    const accessMap = {
      'view': ['read'],
      'edit': ['approve', 'create', 'manage', 'read', 'reject', 'return', 'submit', 'write']
    }
    const accessAction = accessMap[access]
    const selectedPermission = currentPermission.filter(permission => permission.subject === subject)
    // const selectedAccess = selectedPermission.every(permission => accessAction.includes(permission.action))
    const incorrectAccess = selectedPermission.every(permission => ['read', 'read_all'].includes(permission.action) && access === 'edit')
    // console.log('=====subject', subject, access)
    // console.log('*accessAction', accessAction)
    // console.log('*selectedPermission', selectedPermission)
    // console.log('*incorrectAccess', incorrectAccess)
    if (
      !selectedPermission ||
      incorrectAccess
    ) {
      return true
    }
    return false
  }
}
